import React, {useContext, useState, useEffect } from 'react'
import { PlayerContext } from '../App'
import { Container, Row, CardDeck, Card, Button, Spinner, Table, Tab, Tabs} from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'


function Arena() {
    const playerContext = useContext(PlayerContext)
    const { playerName, arenaBestFinish } = playerContext.state
    const [isLoading, setIsLoading] = useState(false)
    const [isGettingPlayers, setIsGettingPlayers] = useState(true)
    const [progressAmount, setProgressAmount] = useState(0)
    const [topPlayers, setTopPlayers] = useState([{username: "", playerName: "", level: 1}]) 


    async function getTopPlayers() {
        try {
            const fetchURL = 'https://k15a1sgec8.execute-api.us-east-1.amazonaws.com/test/arena'
            fetch(fetchURL)
                .then(response => response.json())
                .then(data => setTopPlayers(data))
                .then(console.log(JSON.stringify(topPlayers)))
                .then(setIsGettingPlayers(false))
        } catch (error) {
            console.log("Error: " + JSON.stringify(error))
        }
    }

    useEffect(() => {getTopPlayers()}, [])

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    
    async function handleClick() {
        setIsLoading(true)
        for (let i = 0; i < 10; i++) {
            await sleep(100)
            setProgressAmount(i*10)
        }
        setIsLoading(false)
        playerContext.dispatch( {type: "STARTARENA" } )
    }

    function compare(a, b) {
        if (a.level < b.level) return 1;
        if (b.level < a.level) return -1;
      
        return 0;
    }

    const topPlayersTable = topPlayers.sort(compare).map((toon) => 
        <tr>
            <td>{toon.playerName}</td>
            <td>{toon.username}</td>
            <td>{toon.level}</td>
        </tr>
    )

    return (
        <Container>
            <Row>
                <h1>The Arena!</h1>
            </Row>
            <Tabs defaultActiveKey='mainArena' id='arena'>
                <Tab eventKey ='mainArena' title="Arena">
                    <Card>
                        <Card.Header><b>Welcome to the Arena! Your top finish is: </b> {arenaBestFinish}</Card.Header>
                        <Card.Text>Thousands of monsters have been captured trying to invade town. They cannot be cured.<br/>Take part in competition to see how long you can survive against the monsters of the forest!</Card.Text>
                        <Card.Footer>
                            {isLoading ? (<Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                Preparing Arena: ({progressAmount}%)...
                            </Button>) : (
                            <Button 
                                size='sm' 
                                onClick={() => handleClick() }>
                                Participate
                            </Button>)}
                        </Card.Footer>
                    </Card>
                </Tab>
                <Tab eventKey ='leaderboard' title="Leader Board">
                    <Table striped bordered hover size="sm">
                        <thead>
                            <th>Character Name</th>
                            <th>Username</th>
                            <th>Level Completed</th>
                        </thead>
                        <tbody>
                            {topPlayersTable}
                        </tbody>
                    </Table>
                </Tab>
            </Tabs>
        </Container>
    )
}

export default Arena
