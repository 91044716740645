export const shrivenTownInfo = {
    name: "Shriven",
    banner: "Welcome to Shriven!",
    description: "I don't know, it's the starting town",
    buildings: [
        {
            name: "Happy Inn",
            buildingType: "Inn",
            img: '"./Data/Towns/Shriven/Shop.png"',
            description: "Come have a drink by the fire - maybe listen to a traveling bard.",
            buttonDescription: "Enter the Inn",
        },
        {
            name: "Bob's Happy Shop",
            buildingType: "Shop",
            img: '"./Data/Towns/Shriven/Shop.png"',
            description: "Bob really likes apples",
            buttonDescription: "Enter the Shop",
        },
        {
            name: "Quest Board",
            buildingType: "QuestBoard",
            img: '"./Data/Towns/Shriven/Shop.png"',
            description: "The quest board provides info on locally available quests!",
            buttonDescription: "Visit Board"
        }
    ],
    townsfolk: [
        {
            name: "Bob",
            reputation: 0,
            img: "Mage",
            chat: [
                {
                    minRep: 0,
                    text: "I like apples!"
                },
                {
                    minRep: 5,
                    text: "You're my hero!"
                }
            ],
            questsGiven: [
                {
                    id: 1,
                    repNeeded: 0,
                    repGained: 10
                }
            ]      
        },
        {
            name: "Mayor Billy",
            reputation: 0,
            img: "Priest",
            chat: [
                {
                    minRep: 0,
                    text: "Can you help rebuild our town?"
                },
                {
                    minRep: 5,
                    text: "You've been doing great things around here!"
                }
            ],
            questsGiven: [
                {
                    id: 2,
                    repNeeded: 0,
                    repGained: 10
                }
            ]      
        }
    ]
}