import React, {useContext, useState} from 'react'
import { PlayerContext } from '../App'
import { Container, Row, Card, CardDeck, Button, Alert, Spinner, Col, ProgressBar} from 'react-bootstrap'

function Inn() {
    const playerContext = useContext(PlayerContext)
    const { gold, currentHealth, maxHealth, currentMana, maxMana } = playerContext.state
    const [message, setMessage] = useState('')
    const [isResting, setIsResting] = useState(false)
    const [progressAmount, setProgressAmount] = useState(0)


    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    
    async function handleClick(restType) {
        setIsResting(true)
        let sleepTimer
        restType === 'Eat' ? sleepTimer = 100 : sleepTimer = 1000
        for (let i = 0; i < 10; i++) {
            await sleep(sleepTimer)
            setProgressAmount(i*10)
        }
        setIsResting(false)
        setMessage("You feel refreshed!")
        playerContext.dispatch({ type: 'REST', payload: restType })
    }

    return (
        <Container>
            <Row style={{ textAlign: 'center' }}>
                <h1>Welcome to the Inn</h1>
            </Row>
            <CardDeck>
                <Card border="primary">
                    <Card.Header>Eat a Meal</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Eat a meal and regain 4 of your max health
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        {isResting ? (<Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Resting ({progressAmount}%)...
                        </Button>) : (
                        <Button 
                            disabled={gold < 1}
                            onClick={() => handleClick("Eat")}
                        >Eat (1 Gold)</Button>)}
                    </Card.Footer>
                </Card>
                <Card border="primary">
                    <Card.Header>Get a Room</Card.Header>
                    <Card.Body>
                        <Card.Text>
                            Rent a room and rest for the night. Restores full health.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        {isResting ? (<Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Resting ({progressAmount}%)...
                        </Button>) : (
                        <Button 
                            disabled={gold < 5}
                            onClick={() => handleClick("Room")}
                        >Sleep (5 Gold)</Button>)}
                    </Card.Footer>
                </Card>
            </CardDeck><br />
            <Row><Col>
                <b>Health:</b>
                <ProgressBar 
                    label={currentHealth + "/" + maxHealth}
                    variant='success' 
                    now={(currentHealth/maxHealth) * 100} />
                <b>Mana:</b>
                <ProgressBar 
                    label={currentMana + "/" + maxMana}
                    now={(currentMana/maxMana) * 100} />
            </Col></Row><br/>
            <Row>
                <Col>
                    {message ? (<Alert variant='success'  onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
                </Col>
            </Row><br/>
            <Row>
                <Button onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'TOWN' })}>Go Back to Town</Button>
            </Row>
        </Container>
    )
}

export default Inn