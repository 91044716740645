export const thornbrookShopInventory = [
    {
      name: "Thornbrook Apple",
      type: "Consumable",
      subtype: "Heal",
      quantity: 99,
      minValue: 3,
      maxValue: 3,
      cost: 1,
      sellValue: 1,
    },
    {
      name: "Woodax",
      type: "Other",
      subtype: "Quest",
      quantity: 1,
      minValue: 3,
      maxValue: 3,
      cost: 50,
      sellValue: 1,
    },
    {
      name: "Basic Sword",
      type: "Equipable",
      subtype: "mainHand",
      quantity: 1,
      quality: "Basic",
      minValue: 3,
      maxValue: 3,
      cost: 30,
      sellValue: 15
    },
    {
      name: "Basic Shoulders",
      type: "Equipable",
      subtype: "head",
      quantity: 1,
      quality: "Basic",
      minValue: 5,
      maxValue: 5,
      cost: 30,
      sellValue: 10
    },
    {
      name: "Basic Shield",
      type: "Equipable",
      subtype: "offHand",
      quantity: 1,
      quality: "Basic",
      minValue: 3,
      maxValue: 3,
      cost: 30,
      sellValue: 10
    },
  ]