import React, { useContext, useEffect, useState } from "react"
import { PlayerContext } from "../App"
import { FightContext } from "./Fight"
import { Container, Table, Button, ButtonGroup } from "react-bootstrap"
import { loot } from "../Data/ForestLoot"
import DisplayBackpack from "./DisplayBackpack"
import equipmentGenerator from './EquipmentGenerator'
import DisplayItem from "./DisplayItem"

function Loot() {
  const playerContext = useContext(PlayerContext)
  const fightContext = useContext(FightContext)
  const { monster, fightStatus } = fightContext.fightState
  const { playerName, backpack, forestDepth, currentQuests } = playerContext.state
  const [lootz, setLootz] = useState([])

  useEffect(() => {getLoot()}, [])

  function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  }

  function getLoot() {
    //Load Loot from file
    const lootTable = loot.filter((item) => item.level === forestDepth)

    const chanceQuest = getRandomInt(0, 99)
    const chanceConsumable = getRandomInt(0, 99)
    const chanceEquipable = getRandomInt(0, 99)

    if (chanceQuest >= 1) {
      //find quest items
      const questTable = lootTable.filter((item) => item.subtype === "Quest")

      //if found, check to see if on a quest for it - then add if yes
      if (questTable.length > 0) {
        const randLootNumber = getRandomInt(0, questTable.length)

        const foundQuests = currentQuests.filter((quest) => quest.objective === questTable[randLootNumber].name)
        
        if (foundQuests.length > 0) {
          setLootz(lootz => [...lootz, questTable[randLootNumber]]);
        }  
      }

    }
    if (chanceConsumable >= 1) {
      //find consumables in loot table
      const consumableTable = lootTable.filter((item) => item.type === "Consumable")

      //If found consumables, pick a random one and add it to the lootz array
      if (consumableTable.length > 0) {
        const randLootNumber = getRandomInt(0, consumableTable.length)
        setLootz(lootz => [...lootz, consumableTable[randLootNumber]])
      }
    }

    if (chanceEquipable >= 1) {
      //find equipment in loot table
      const equipableTable = lootTable.filter((item) => item.type === "Equipable")
      //If found equipment, pick a random one and add it to the lootz array
      if (equipableTable.length > 0) {
        const randLootNumber = getRandomInt(0, equipableTable.length)
        const baseItem = JSON.parse(JSON.stringify(equipableTable[randLootNumber]))
        //Modify base equipment
        if (baseItem.bonus) {
          //Item already has a bonus and does not need to be modified
          setLootz(lootz => [...lootz, baseItem])
        } else {
          //Generate bonus for item
          const updatedItem = equipmentGenerator(forestDepth, baseItem)
          // console.log("Generated Item = " + JSON.stringify(updatedItem))
          setLootz(lootz => [...lootz, updatedItem])
        }
      }
    }
  }

  function pickupItem(e) {
    // console.log("picking up " + e.name)
    let count = 1

    //look to see if there is already one in the backpack
    backpack.map((element) => {
      if (element.name === e.name) {
        count = element.quantity + 1
      }
    })
    playerContext.dispatch({
      type: "ADDITEM",
      payload: {
        name: e.name,
        type: e.type,
        subtype: e.subtype,
        quality: e.quality,
        bonus: e.bonus,
        quantity: count,
        minValue: e.minValue,
        maxValue: e.maxValue,
        sellValue: e.sellValue,
      },
    })

    if (e.subtype === "Quest") {
      //check to see if on quest
      const foundQuests = currentQuests.filter(
        (quest) => quest.objective === e.name
      )

      //if on quest update quest progress
      foundQuests.forEach((q) => {
        // console.log("Found Matching Quest: " + q.name)
        const newProgress = q.progress + 1
        //If yes, update the quest progress
        playerContext.dispatch({ type: "PICKUPQUEST", payload: {...q, progress: newProgress } })
      })
    }
    setLootz(lootz.filter(item => item.name !== e.name))
  }
  const flee = (destination) => {
    playerContext.dispatch({ type: "GOTO", payload: destination })
  }

  const showLootz = lootz.map((item) => 
    <tr>
      <DisplayItem item={item}/>
      <td><Button size="sm" onClick={() => pickupItem(item)}>Pickup</Button></td>
    </tr>
  )

  return (
    <Container>
      <h3>You Collect:</h3>
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Item Name</th>
            <th>Type</th>
            <th>Value</th>
            <th>Pickup?</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Gold</td>
            <td>Gold</td>
            <td>{monster.goldDropped}</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Essence</td>
            <td>Essence</td>
            <td>{monster.essenceDropped}</td>
            <td>-</td>
          </tr>
          {showLootz}
        </tbody>
      </Table>
      <DisplayBackpack />
      <br />
      <Container border="primary">
        <h3>Where To?</h3>
        <Button onClick={() => flee("TOWN")}>Head to Town</Button>
        {forestDepth < 1 ? ("") : (
            <Button onClick={() => flee("FOREST")}>Back to Forest</Button>
        )}
      </Container>
    </Container>
  )
}

export default Loot
