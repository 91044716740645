import React, { useContext, useEffect } from 'react'
import { PlayerContext } from '../App'
import { Card, Button } from 'react-bootstrap'

function ArenaVictory() {
    const playerContext = useContext(PlayerContext)
    const { arenaLevel } = playerContext.state
    
    function handleNextMatchClick() {
        playerContext.dispatch({ type: 'ARENANEXTMATCH' })
    }

    function handleEndRunClick() {
        playerContext.dispatch({ type: 'ARENAEND' })
    }

    return (
        <Card>
            <Card.Header>Congrats!</Card.Header>
            <Card.Body><Card.Text>You've defeated Round #{arenaLevel}</Card.Text></Card.Body>
            <Card.Footer>
                <Button 
                    size='sm' 
                    onClick={() => handleNextMatchClick() }>
                    Next Match
                </Button>
                <Button 
                    size='sm' 
                    onClick={() => handleEndRunClick() }>
                    End Run
                </Button>
            </Card.Footer>
        </Card>
    )
}

export default ArenaVictory
