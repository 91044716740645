import React from 'react'
import { Button, Popover, OverlayTrigger } from 'react-bootstrap'

function DisplayItemButton(props) {
    const item = props.item

    let itemStyle = "secondary"

    if (item.quality === "Iron") {
        itemStyle = "success"
    } else if (item.quality === "Steel") {
        itemStyle = "warning"
    } else if (item.quality === "Mithril") {
        itemStyle = "danger"
    }

    function popover (item) {
        return (
            <Popover id="popover-basic">
                <Popover.Title as="h3">{item.name}</Popover.Title>
                <Popover.Content>
                    <b>Slot:</b> {item.subtype}
                    {item.subtype === "mainHand" ? (<p><b>Damage:</b> {item.minValue} - {item.maxValue}</p>) : (<p><b>Armor:</b> {item.minValue}</p>)}
                    {item.bonus ? (
                        <React.Fragment>
                            <b>Bonuses:</b><br />
                            {item.bonus.abilities.map((bonus) => 
                                <React.Fragment>{bonus}: {item.bonus.abilityMod}<br/></React.Fragment>
                            )}
                        </React.Fragment>
                    ) : (null)}
                </Popover.Content>
            </Popover>
        )
    }

    return (
        <OverlayTrigger trigger='click' placement="right" overlay={popover(item)}>
            <Button variant={itemStyle} size='sm'>{item.name}</Button>
        </OverlayTrigger>
    )
}

export default DisplayItemButton