import React, {useContext, useState, useEffect } from 'react'
import { PlayerContext } from '../App'
import { Container, Row, CardDeck, Card, Button, Spinner, Dropdown, ButtonGroup, DropdownButton, Tab, Tabs} from 'react-bootstrap'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'


function Forest() {
    const playerContext = useContext(PlayerContext)
    const { playerName, maxForestDepth } = playerContext.state
    const [isLoading, setIsLoading] = useState(false)
    const [progressAmount, setProgressAmount] = useState(0)
    const [forestLevelArray, setForestLevelArray] = useState([])
    const [selectedForestLevel, setSelectedForestLevel] = useState(1)

    useEffect(() => {
        let tempArray = [1]
        if (maxForestDepth === 2) { tempArray.push(2)}
        else if (maxForestDepth === 3) { tempArray.push(3)}
        setForestLevelArray(tempArray)
    }, [])

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    
    async function handleClick() {
        setIsLoading(true)
        for (let i = 0; i < 10; i++) {
            await sleep(100)
            setProgressAmount(i*10)
        }
        setIsLoading(false)
        playerContext.dispatch({ type: 'FIELD', fieldName: 'forestDepth', payload: selectedForestLevel})
        playerContext.dispatch({ type: 'GOTO', payload: 'HUNT' })
    }

    const forestLevels = forestLevelArray.map((level) => (
        <Dropdown.Item
          name="forestLevel"
          value={level}
          onSelect={() => setSelectedForestLevel(level)} >
          {level}
        </Dropdown.Item>
      ))

    return (
        <Container>
            <Row>
                <h1>The Corrupted Forest</h1>
            </Row>
            <Tabs defaultActiveKey='hunt' id='ForestTabs'>
                <Tab eventKey ='hunt' title="Hunt Monsters">
                    <Card>
                        <Card.Header><b>Hunt For Monsters</b></Card.Header>
                        <Card.Text>Look for a monster to fight. Harvest essence if you are successful.</Card.Text>
                        <Card.Footer>
                            {isLoading ? (<Button variant="primary" disabled>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                Hunting for Monsters ({progressAmount}%)...
                            </Button>) : (
                                    <ButtonGroup style={{ padding: "1rem" }}>
                                    <DropdownButton
                                        variant="success"
                                        size='sm'
                                        id="spell-dropdown"
                                        title="Forest Depth">
                                        {forestLevels}
                                    </DropdownButton>
                                    <Button
                                        size='sm'
                                        variant="success"
                                        onClick={() => handleClick() }
                                    >Enter Level: {selectedForestLevel}</Button>
                                    </ButtonGroup>
                            )}
                        </Card.Footer>
                    </Card>
                </Tab>
                <Tab eventKey ='base' title="Forest Base">
                    <Card>
                        <Card.Header><b>Forest Base</b></Card.Header>
                        <Card.Text>Your base to construct and upgrade, the high the level of your base the deeper into the forest you can travel.</Card.Text>
                        <Card.Footer>
                            <Button 
                                onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'ForestBase' })}
                            >Enter Base</Button>
                        </Card.Footer>
                    </Card>
                </Tab>
                {/*<Tab eventKey ='gather' title="Gather Resources">
                    <Card>
                        <Card.Header><b>Gather Resources</b></Card.Header>
                        <Card.Text>Look around the forest for items to help build your base.</Card.Text>
                        <Card.Footer>
                            <Button 
                                onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'Gather' })}
                            >Gather</Button>
                        </Card.Footer>
                    </Card>
                </Tab>*/}
            </Tabs>
        </Container>
    )
}

export default Forest
