import React, {useContext, useState } from 'react'
import { PlayerContext } from '../App'
import { storyboards } from '../Data/StoryBoard'
import { Button , Container, Card} from 'react-bootstrap'

function Story() {
    const playerContext = useContext(PlayerContext)
    const { storyBoardNum } = playerContext.state
    const [ storyNum, setStoryNum ] = useState(storyBoardNum - 1)

    function gotoTown () {
        //turn off inCombat flag (using that to disable Nav)
        playerContext.dispatch({ type: 'FIELD', fieldName: 'inCombat', payload: false})
        //goto town
        playerContext.dispatch({ type: 'GOTO', payload: 'TOWN' })
    }

    return (
        <Container>
            <Card border="primary">
                <Card.Header><h4>{storyboards[storyNum].name}</h4></Card.Header>
                <Card.Text>{storyboards[storyNum].text}</Card.Text>
                <Card.Footer>
                    {storyboards[storyNum].fight ? (
                        <Button onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'HUNT' })}>Fight!</Button>
                    ) : (
                        <React.Fragment>
                            {storyboards[storyNum].previousBoard ? (<Button onClick={() => setStoryNum(storyNum - 1)}>Previous</Button>) : ("")}
                            {storyboards[storyNum].nextBoard ? (<Button onClick={() => setStoryNum(storyNum + 1)}>Next</Button>) 
                                : (<Button onClick={() => gotoTown()}>Go to Town</Button>)}
                        </React.Fragment>
                    )}
                </Card.Footer>
            </Card>
        </Container>
    )
}

export default Story
