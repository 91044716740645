import React from 'react'
import { Card, ListGroup, Button, Popover, OverlayTrigger } from 'react-bootstrap'
import DisplayItemButton from './DisplayItemButton'

function DisplayQuest(props) {
    const quest = props.quest

    const basicStyle = "secondary"
    const ironStyle = "success"
    const steelStyle = "warning"
    const mithrilStyle = "danger"

    function popover (item) {
        return (
            <Popover id="popover-basic">
                <Popover.Title as="h3">{item.name}</Popover.Title>
                <Popover.Content>
                    <b>Slot:</b> {item.subtype}
                    {item.subtype === "mainHand" ? (<p><b>Damage:</b> {item.minValue} - {item.maxValue}</p>) : (<p><b>Armor:</b> {item.minValue}</p>)}
                    {item.bonus ? (
                        <React.Fragment>
                            <b>Bonuses:</b><br />
                            {item.bonus.abilities.map((bonus) => 
                                <p>{bonus}: {item.bonus.abilityMod}</p>
                            )}
                        </React.Fragment>
                    ) : (null)}
                </Popover.Content>
            </Popover>
        )
    }

    const rewards = quest.reward.map((reward) =>
        <React.Fragment>
            {reward.type === "Other" && 
                <ListGroup.Item>{reward.quantity} {reward.name}</ListGroup.Item>
            }
            {reward.type === "Consumable" && 
                <ListGroup.Item>{reward.quantity} {reward.name}</ListGroup.Item>
            }
            {reward.type === "Equipable" &&
                <React.Fragment>
                    {reward.quality === "Basic" ? (
                        <ListGroup.Item variant={basicStyle}>
                            <DisplayItemButton item={reward} />
                        </ListGroup.Item>
                    ) : (null)}
                    {reward.quality === "Iron" ? (
                        <ListGroup.Item variant={ironStyle}>
                            <DisplayItemButton item={reward} />
                        </ListGroup.Item>
                    ) : (null)}
                    {reward.quality === "Steel" ? (
                        <ListGroup.Item variant={steelStyle}>
                            <DisplayItemButton item={reward} />
                        </ListGroup.Item>) : (null)}
                    {reward.quality === "Mithril" ? (
                        <ListGroup.Item variant={mithrilStyle}>
                            <DisplayItemButton item={reward} />
                        </ListGroup.Item>) : (null)}
                </React.Fragment>
            }
        </React.Fragment>
    )

    return (
        <React.Fragment>
            <Card.Header>{quest.name}</Card.Header>
            <Card.Body>
                <Card.Text>{quest.description}</Card.Text>
                <ListGroup>
                    <ListGroup.Item><b>Objectives:</b></ListGroup.Item>
                    {quest.questType === "collection" && 
                        <ListGroup.Item>Collect {quest.progress}/{quest.totalAmountNeeded} {quest.objective}</ListGroup.Item>
                    }
                    {quest.questType === "kill" &&
                        <ListGroup.Item>Kill {quest.progress}/{quest.totalAmountNeeded} {quest.objective}</ListGroup.Item>
                    }
                </ListGroup><br />
                <ListGroup>
                    <ListGroup.Item><b>Rewards:</b></ListGroup.Item>
                    {rewards}
                </ListGroup>
            </Card.Body>
        </React.Fragment>
    )
}

export default DisplayQuest