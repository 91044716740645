import React, { useContext, useEffect } from 'react'
import { PlayerContext } from '../App'
import { FightContext } from './Fight'
import { Card, Button } from 'react-bootstrap'

function FightVictory() {
    const playerContext = useContext(PlayerContext)
    const fightContext = useContext(FightContext)
    const { monster } = fightContext.fightState

    useEffect(() => {getGold()}, [])
    
    function getGold() {
        playerContext.dispatch({type: "VICTORY", payload: {
              goldDropped: monster.goldDropped,
              essenceDropped: monster.essenceDropped}
        })
    }

    function handleClick () {
        console.log("Fight Victory Clicked Gather Loot")
        fightContext.fightDispatch({type:"LOOT", playerData: playerContext})
    }
    return (
        <Card.Footer>
            <Button 
                size='sm' 
                onClick={() => handleClick() }>
                Gather Loot
            </Button>
        </Card.Footer>
    )
}

export default FightVictory
