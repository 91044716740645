import { monsters } from "../Data/ForestMonsters"

function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
}

function getDebuffValue (monsterDebuffs) {
    monsterDebuffs.map((monsterdebuff) => {
        // console.log("Monster has debuff: " + monsterdebuff.name)
        switch (monsterdebuff.name) {
        case "Scorched":
            return monsterdebuff.value
        default:
            return 0
        }
    })
}

function getPlayerDamage (playerData, monster) {
    let weaponDamage = getRandomInt(playerData.state.minDamage, playerData.state.maxDamage) + playerData.state.attackPower
    let gotCrit = false
    const critRoll = getRandomInt(0,99)
    if (playerData.state.critChance > critRoll) {
        weaponDamage = weaponDamage + weaponDamage * (playerData.state.critDamage / 100)
        gotCrit = true
    }
    return [weaponDamage, gotCrit]
}

function getSpellHeal (playerData, spellCast) {
    return playerData.state.magicPower + spellCast.punch
}

function getSpellDamage (playerData, spellCast) {
    let spellDamage = playerData.state.magicPower + spellCast.punch
    let gotCrit = false
    const critRoll = getRandomInt(0,99)
    if (playerData.state.magicCritChance > critRoll) {
        spellDamage = spellDamage * 2
        gotCrit = true
    }
    return [spellDamage, gotCrit]
} 

function getSpellCost (spellCast) {
    return spellCast.manaCost
}

function getMonsterDamage (playerData, monster) {
    const dodgeRoll = getRandomInt(0,99)
    let gotDodge = false
    let damageDone = 0
    if (playerData.state.dodge > dodgeRoll) {
        gotDodge = true
    } else {
        damageDone = parseInt(monster.power * (100/(100 + playerData.state.armor)))
    }
    return [damageDone, gotDodge]
}

function fightReducer(fightState,action) {
    let playerData
    switch(action.type) {
        case 'SETMESSAGES':
            return {
                ...fightState,
                playerMessage: action.playerMessage,
                monsterMessage: action.monsterMessage
            }
        case 'INITIATE':
            //Load Random Monster from file
            playerData = action.playerData.state

            //console.log("PD = " + JSON.stringify(playerData))
            if (playerData.inArena) {
                const randomMonster = monsters[Math.floor(Math.random()*monsters.length)]
                return {
                    ...fightState,
                    monster: randomMonster,
                    playerMessage: "Round #" + playerData.arenaLevel
                }
            } else {
                const loadedMonsters = monsters.filter((monster) => monster.level === action.forestDepth)
                const randMonsterNumber = getRandomInt(0, loadedMonsters.length)
    
                return {
                    ...fightState,
                    monster: loadedMonsters[randMonsterNumber],
                    playerMessage: "Welcome to the Fight"
                }
            }
        case 'LOOT':
            playerData = action.playerData
            
            //Take Player out of combat
            playerData.dispatch({
                type: "FIELD",
                fieldName: "inCombat",
                payload: false})

            //Check to see if any quests need to be updated
            const foundQuests = playerData.state.currentQuests.filter(
                (quest) => quest.objective === fightState.monster.name)
          
            foundQuests.forEach((q) => {
                // console.log("Found Matching Quest: " + q.name);
                const newProgress = q.progress + 1;
                //If yes, update the quest progress
                playerData.dispatch({
                  type: "PICKUPQUEST",
                  payload: {
                      ...q,
                      progress: newProgress
                  }})
                //   payload: {id: q.id, name: q.name,description: q.description, status: q.status, type: q.type, objective: q.objective,
                //     totalAmountNeeded: q.totalAmountNeeded, progress: newProgress,reward: q.reward}})
            })
            return {
                ...fightState,
                fightStatus: "Loot"
            }
        case 'ATTACK':
            playerData = action.playerData
            let monNewHealth = fightState.monster.currentHealth
            let playerNewHealth = action.playerData.state.currentHealth
            let newFightStatus = fightState.fightStatus
            let newPlayerMessage = ""
            let newMonsterMessage = ""
            let playerNewMana = playerData.state.currentMana
            let spellCast, gotCrit, gotDodge, playerDamage, monsterDamage, playerHealed
            //Check on any debuffs applied from previous rounds, return damage if appropriate
            let monDebuffValue = 0
            if (fightState.monster.debuffs.length > 0) {
                monDebuffValue = getDebuffValue(fightState.monster.debuffs)}

            //Decide who goes first - right now just set to player going first
            const playerInitative = 10
            const monsterInitative = 1

            //If Player has the high initative:
            if (playerInitative > monsterInitative) {
                if (action.attackType === "weapon") {
                    [playerDamage, gotCrit] = getPlayerDamage(action.playerData, fightState.monster)
                    playerHealed = 0
                } else {
                    playerData.state.spellList.map((spell) => {
                        if (action.spell === spell.name) {
                          spellCast = spell
                        }})
                    if (spellCast.type === "Damage") {
                        [playerDamage, gotCrit] = getSpellDamage(action.playerData, spellCast)
                        playerNewMana = playerData.state.currentMana -  getSpellCost(spellCast)  
                        playerHealed = 0
                    } else if (spellCast.type === "Heal") {
                        playerHealed = getSpellHeal(action.playerData, spellCast)
                        playerNewMana = playerData.state.currentMana -  getSpellCost(spellCast)  
                        playerDamage = 0
                    } else {
                        playerDamage = 0
                        playerHealed = 0
                    }
                }
                //console.log("Player Damage = " + playerDamage + " mondeb: " + monDebuffValue)
                //Check to see if monster dead
                if ( (playerDamage + monDebuffValue) >= fightState.monster.currentHealth) {
                    //No Monster Attack
                    //console.log("in monster dead code")
                    monNewHealth = 0
                    if (action.playerData.state.inArena) {
                        newFightStatus = "ArenaVictory"
                    } else {
                        newFightStatus = "Victory"
                    }
                } else {
                    //Monster Attacks
                    monNewHealth = fightState.monster.currentHealth - playerDamage - monDebuffValue
                    const damReturn = getMonsterDamage(action.playerData, fightState.monster)
                    monsterDamage = damReturn[0]
                    gotDodge = damReturn[1]
                    if ((monsterDamage - playerHealed) >= action.playerData.state.currentHealth) {
                        playerNewHealth = 0
                        if (action.playerData.state.inArena) {
                            newFightStatus = "ArenaDefeat"
                        } else {
                            newFightStatus = "Defeat"
                        }
                    } else {
                        // console.log("CH = " + action.playerData.state.currentHealth + " :: MD= " + monsterDamage + " :: PH= " + playerHealed)
                        playerNewHealth = action.playerData.state.currentHealth - monsterDamage + playerHealed
                        if (playerNewHealth > action.playerData.state.maxHealth) {
                            playerNewHealth = action.playerData.state.maxHealth
                        }
                    }
                }
                action.playerData.dispatch({
                    type: "FIELD",
                    fieldName: "currentHealth",
                    payload: playerNewHealth,
                  })
                action.playerData.dispatch({
                    type: "FIELD",
                    fieldName: "currentMana",
                    payload: playerNewMana,
                    })
                
                //Check to see if player got a crit, change message if they did
                let damageMessage
                if (gotCrit) {
                    damageMessage = "CRITICAL STRIKE! You hit for " + playerDamage
                } else {
                    damageMessage = "You hit for " + playerDamage
                }

                //Check to see if player dodged attack, change message if they did
                let monDamageMessage
                if (gotDodge) {
                    monDamageMessage = "DODGE! You dodged the monsters attack"
                } else {
                    monDamageMessage = fightState.monster.name + " hits you for " + monsterDamage
                }

                if (newFightStatus === "Victory") {
                    newPlayerMessage = damageMessage + ". You have defeated " + fightState.monster.name
                    newMonsterMessage = "uuuggghhhhh...."
                } else if (newFightStatus === "Defeat") {
                    newPlayerMessage = damageMessage + ". You have been defeated by " + fightState.monster.name
                    newMonsterMessage = monDamageMessage + ". RAWWWRRR!!"
                } else {
                    newPlayerMessage = damageMessage + "."
                    newMonsterMessage = monDamageMessage + "."
                }
            } else {
                //ADD THIS LATER WHEN ADDING INITIATIVE
                //Calc Monster Attack
                //CHeck
                //Calc Player Attack
                //Check to see if mon alive
            }

            return {
                ...fightState,
                playerMessage: newPlayerMessage,
                monsterMessage: newMonsterMessage, 
                fightStatus: newFightStatus,
                monster: {...fightState.monster,
                    currentHealth: monNewHealth
                }
            }
        case 'SPELLATTACK':

            return {
                ...fightState,
                playerMessage: "You cast a spell!"
            }
        default:
          return fightState
      }
    }

export default fightReducer