function getRandomInt(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
}

function pickBonus(num, forestDepth) {
    const halfMod = Math.round(forestDepth/2)
    const bonusTypes = [
        {name: "Fire", abilities: ["strength"], abilityMod: forestDepth },
        {name: "Water", abilities: ["spirit"], abilityMod: forestDepth },
        {name: "Air", abilities: ["intelligence"], abilityMod: forestDepth },
        {name: "Earth", abilities: ["constitution"], abilityMod: forestDepth },
        {name: "Ice", abilities: ["agility"], abilityMod: forestDepth },
        {name: "Mud", abilities: ["constitution", "spirit"], abilityMod: halfMod },
        {name: "Steam", abilities: ["strength", "intelligence"], abilityMod: halfMod },
        {name: "Vapor", abilities: ["intelligence", "spirit"], abilityMod: halfMod },
        {name: "Brick", abilities: ["constitution", "strength"], abilityMod: halfMod },
        {name: "Dust", abilities: ["constitution", "intelligence"], abilityMod: halfMod },
        {name: "Boiling", abilities: ["strength", "spirit"], abilityMod: halfMod },
        {name: "Glacial", abilities: ["agility", "constitution"], abilityMod: halfMod},
        {name: "Freezing", abilities: ["agility", "spirit"], abilityMod: halfMod},
        {name: "Breath", abilities: ["agility", "intelligence"], abilityMod: halfMod},
        {name: "Song", abilities: ["agility", "strength"], abilityMod: halfMod},
    ]

    const extraBonusTypes = [
        {name: "Striking", abilities: ["critChance"], abilityMod: forestDepth },
        {name: "Smashing", abilities: ["critDamage"], abilityMod: forestDepth * 10 },
        {name: "Dodging", abilities: ["dodge"], abilityMod: forestDepth },
    ]
    if (num === 1) {
        const randBonus = getRandomInt(0,3)
        return bonusTypes[randBonus]
    } else if (num === 2) {
        const randBonus = getRandomInt(0,9)
        return bonusTypes[randBonus]
    } else if (num === 3) {
        //Need to add in secondary bonus eventually - maybe magic crit, stealth, crit, etc...
        const randBonus = getRandomInt(0,9)
        return bonusTypes[randBonus]
    }
}

function equipmentGenerator (forestDepth, pickedItem) {
    // console.log("PI: " + JSON.stringify(pickedItem))
    //roll for quality (basic (not pre), iron, steel, mithril)
    const randQualityRand = getRandomInt(1,10) * forestDepth

    console.log("randquality  = " + randQualityRand + " Forest Depth = " + forestDepth)
    //Final will be: 0-9 Normal, 10 - 19 Iron, 20-29 Steel, 30-39 Mithril
    //Add in || forestDepth === 0 so plain stick drops after first fight - also modify this 
    if (randQualityRand < 9) {
        //No mods, just basic item
        pickedItem.quality = "Basic"
        pickedItem.name = "Basic " + pickedItem.name
        return pickedItem
    } else if (randQualityRand >= 9 && randQualityRand < 19) {
        //if level >= 2 - roll for modifier
        pickedItem.quality = "Iron"
        pickedItem.minValue = Math.round(pickedItem.minValue * 2)
        pickedItem.maxValue = Math.round(pickedItem.maxValue * 2)
        pickedItem.bonus = pickBonus(1, forestDepth)
        pickedItem.name = pickedItem.quality + " " + pickedItem.name + " of " + pickedItem.bonus.name
        return pickedItem
    } else if (randQualityRand >= 19 && randQualityRand <39) {
        pickedItem.quality = "Steel"
        pickedItem.minValue = Math.round(pickedItem.minValue * 3)
        pickedItem.maxValue = Math.round(pickedItem.maxValue * 3)
        pickedItem.bonus = pickBonus(2, forestDepth)
        pickedItem.name = pickedItem.quality + " " + pickedItem.name + " of " + pickedItem.bonus.name
        return pickedItem
    } else {
        // console.log("Mithril level")
        return pickedItem
    }
}

export default equipmentGenerator