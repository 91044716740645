import React, {useContext, useState, useEffect} from 'react'
import { PlayerContext } from '../App'
import Password from './Password'
import { Tab, Container, Tabs, Button, Row, Col, Spinner, Table, Alert, CardDeck, Card } from 'react-bootstrap'
import DisplayToons from './DisplayToons'

function Account() {
    const playerContext = useContext(PlayerContext)
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const { playerName, username } = playerContext.state

    async function saveGame () {
        const savedGame = JSON.stringify(playerContext.state)

        try {
            fetch('https://k15a1sgec8.execute-api.us-east-1.amazonaws.com/test/players', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: savedGame,
            })
            .then(response => response.json())
            .then(data => {
                console.log("data = " + JSON.stringify(data))
                setMessage("Saved " + playerName)
            })
        } catch (error) {
            setErrorMessage("Can't save game: " + error['message'])
        }
    }

    function logout() {
        playerContext.dispatch({ type: 'RESTART' })
        playerContext.dispatch({ type: 'FIELD', fieldName: 'isLoginScreen', payload: true})
        localStorage.removeItem("containedRPG-localState")
    }

    return (
        <Container>
            <Tabs defaultActiveKey='accountDetail' id='account'>
                <Tab eventKey ='accountDetail' title="Account">
                    <Row><Col><h2>Account: {username}</h2></Col></Row>
                    <CardDeck>
                        <Card border="primary">
                            <Card.Header>Save Game - Cloud</Card.Header>
                            <Card.Text>Please save your character often! You can only have one save point per character. This save can be accessed on any device.</Card.Text>
                            <Card.Footer>
                                <Button onClick={() => saveGame()}>Save Game</Button>
                            </Card.Footer>
                        </Card>
                        <Card border="primary">
                            <Card.Header>Log Off</Card.Header>
                            <Card.Text>Log out of your character.</Card.Text>
                            <Card.Footer>
                                <Button onClick={() => logout()}>Logout</Button>
                            </Card.Footer>
                        </Card>
                    </CardDeck>
                </Tab>
                <Tab eventKey ='characterDetail' title="Characters">
                    <DisplayToons />
                </Tab>
                <Tab eventKey ='passwordSettings' title="Password Settings">
                    <Password />
                </Tab>
            </Tabs>
            <Row>
                <Col>{message ? (<Alert variant='success' onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
                {errorMessage ? (<Alert variant='danger'  onClose={() => setErrorMessage("")} dismissible>{errorMessage}</Alert>) : ("")}</Col>
            </Row>
        </Container>
    )
}

export default Account
