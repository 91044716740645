export const loot = [
  {
    name: "Stick",
    type: "Equipable",
    subtype: "mainHand",
    quality: "Basic",
    quantity: 1,
    minValue: 1,
    maxValue: 2,
    cost: 1,
    level: 0,
    sellValue: 1
  }, 
  {
    name: "Wormy Apple",
    type: "Consumable",
    subtype: "Heal",
    quality: "Basic",
    quantity: 1,
    minValue: 1,
    maxValue: 1,
    cost: 1,
    level: 0,
    sellValue: 1,
  }, 
  {
    name: "Apple",
    type: "Consumable",
    subtype: "Heal",
    quality: "Basic",
    quantity: 2,
    minValue: 3,
    maxValue: 3,
    cost: 1,
    level: 1,
    sellValue: 1,
  },
  {
    name: "Green Apple",
    type: "Other",
    subtype: "Quest",
    quality: "Basic",
    quantity: 1,
    minValue: 1,
    maxValue: 1,
    cost: 1,
    level: 1,
    sellValue: 1,
  },
  {
    name: "Dagger",
    type: "Equipable",
    subtype: "mainHand",
    quality: "Basic",
    quantity: 1,
    minValue: 3,
    maxValue: 5,
    cost: 5,
    level: 1,
    sellValue: 1
  },
  {
    name: "Helmet",
    type: "Equipable",
    subtype: "head",
    quality: "Basic",
    quantity: 1,
    minValue: 3,
    maxValue: 3,
    cost: 5,
    level: 1,
    sellValue: 1
  },
  {
    name: "Chest",
    type: "Equipable",
    subtype: "chest",
    quality: "Basic",
    quantity: 1,
    minValue: 2,
    maxValue: 2,
    cost: 5,
    level: 1,
    sellValue: 7,
  },
  {
    name: "Boots",
    type: "Equipable",
    subtype: "feet",
    quality: "Basic",
    quantity: 1,
    minValue: 3,
    maxValue: 3,
    cost: 5,
    level: 1,
    sellValue: 1
  },
  {
    name: "Guantlets",
    type: "Equipable",
    subtype: "hands",
    quality: "Basic",
    quantity: 1,
    minValue: 2,
    maxValue: 2,
    cost: 5,
    level: 1,
    sellValue: 5,
  },
  {
    name: "Leggings",
    type: "Equipable",
    subtype: "pants",
    quality: "Basic",
    quantity: 1,
    minValue: 2,
    maxValue: 2,
    cost: 5,
    level: 1,
    sellValue: 5,
  },
  {
    name: "Boots",
    type: "Equipable",
    subtype: "feet",
    quality: "Basic",
    quantity: 1,
    minValue: 2,
    maxValue: 2,
    cost: 5,
    level: 1,
    sellValue: 5,
  },
  {
    name: "Crystal",
    type: "Other",
    subtype: "Gem",
    quality: "Basic",
    quantity: 1,
    minValue: 3,
    maxValue: 3,
    cost: 50,
    level: 2,
    sellValue: 10,
  },
  {
    name: "Sword",
    type: "Equipable",
    subtype: "mainHand",
    quality: "Basic",
    quantity: 1,
    minValue: 8,
    maxValue: 13,
    cost: 500,
    level: 2,
    sellValue: 10,
  },
  {
    name: "Shoulders",
    type: "Equipable",
    subtype: "shoulders",
    quality: "Basic",
    quantity: 1,
    minValue: 8,
    maxValue: 13,
    cost: 500,
    level: 2,
    sellValue: 10,
  },
  {
    name: "Chest",
    type: "Equipable",
    subtype: "chest",
    quality: "Basic",
    quantity: 1,
    minValue: 8,
    maxValue: 13,
    cost: 500,
    level: 2,
    sellValue: 10,
  },
]