import React, {useContext, useState, useEffect} from 'react'
import { PlayerContext } from '../App'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Badge from 'react-bootstrap/Badge'
import { GiForest, GiAbstract074, GiBlackBook, GiSwordTie, GiMushroomCloud, GiField, GiBattleGear, GiCastle} from 'react-icons/gi'
import { FiUser } from 'react-icons/fi'

function Header() {
    const playerContext = useContext(PlayerContext)
    const { username, isLoginScreen, inCombat, playerName, currentQuests } = playerContext.state
    const [questCompleteCount, setQuestCompleteCount] = useState(0)

    const handleSelect = (eventKey) => playerContext.dispatch({ type: 'GOTO', payload: eventKey })

    useEffect(() => {getQuestCompleteCount()})
  
    function getQuestCompleteCount() {
      let newCount = 0
      currentQuests.forEach((quest) => {
        if (quest.progress>=quest.totalAmountNeeded && quest.status !== "Complete") {
          newCount += 1
        }
        setQuestCompleteCount(newCount)
      }) 
    } 

  return (
    <Navbar sticky='top' bg="dark" variant="dark" collapseOnSelect expand='sm' onSelect={handleSelect}>
        {!isLoginScreen && !inCombat ? (
            <React.Fragment>
                <Navbar.Brand><GiMushroomCloud />Contained RPG</Navbar.Brand>
                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link eventKey="CHARDETAILS"><GiBattleGear />{playerName} Info</Nav.Link>
                        <Nav.Link eventKey="TOWN"><GiCastle />Town</Nav.Link>
                        <Nav.Link eventKey="GAMEMAP"><GiField />Map</Nav.Link>
                        <Nav.Link eventKey="FOREST"><GiForest />Forest</Nav.Link>
                        <Nav.Link eventKey="ESSENCE"><GiAbstract074 />Essence</Nav.Link>
                        {questCompleteCount > 0 ? 
                            (<Nav.Link eventKey="QUESTLOG"><GiBlackBook />Quest Log <Badge variant="success">{questCompleteCount}</Badge>
                            </Nav.Link>) 
                            : (<Nav.Link eventKey="QUESTLOG"><GiBlackBook />Quest Log</Nav.Link>)
                        }
                        <Nav.Link eventKey="ARENA"><GiSwordTie />Arena</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Nav>
                    <Nav.Link eventKey="Account"><FiUser />{username}</Nav.Link>
                </Nav>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Navbar.Brand><GiMushroomCloud />Contained RPG</Navbar.Brand>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                </Navbar.Collapse>
            </React.Fragment>
        )}
    </Navbar>
  )
}

export default Header
