import React, {useContext} from 'react'
import { PlayerContext } from '../App'
import Container from 'react-bootstrap/Container'
import Town from './Town'
import Account from './Account'
import Forest from './Forest'
import Shop from './Shop'
import Fight from './Fight'
import Essence from './Essence'
import Inn from './Inn'
import CharDetails from './CharDetails'
import QuestBoard from './QuestBoard'
import QuestLog from './QuestLog'
import Story from './Story'
import LoadCharacter from './LoadCharacter'
import GameMap from './GameMap'
import Gather from './Gather'
import ForestBase from './ForestBase'
import Arena from './Arena'
import BaseUpgradeComplete from './BaseUpgradeComplete'
import ArenaVictory from './ArenaVictory'
import ArenaDefeat from './ArenaDefeat'
import Home from './Home'

function MainScreen() {
    const playerContext = useContext(PlayerContext)
    const { display} = playerContext.state
    
    return (
        <Container style={{ padding: '2rem' }}>
          {display === "STORY" ? (<Story />) : ""}
          {display === "TOWN" ? (<Town />) : ""}
          {display === "FOREST" ? (<Forest />) : ""}
          {display === "Shop" ? (<Shop />) : ""}
          {display === "HUNT" ? (<Fight />) : ""}
          {display === "ESSENCE" ? (<Essence />) : ""}
          {display === "Inn" ? (<Inn />) : ""}
          {display === "CHARDETAILS" ? (<CharDetails />) : ""}
          {display === "QuestBoard" ? (<QuestBoard />) : ""}
          {display === "QUESTLOG" ? (<QuestLog />) : ""}
          {display === "LOADCHARACTER" ? (<LoadCharacter />) : ""}
          {display === "GAMEMAP" ? (<GameMap />) : ""}
          {display === "Gather" ? (<Gather />) : ""}
          {display === 'ForestBase' ? (<ForestBase />) : ""}
          {display === 'BaseUpgradeComplete' ? (<BaseUpgradeComplete />) : ""}
          {display === 'Account' ? (<Account />) : ""}
          {display === "ARENA" ? (<Arena />) : ""}
          {display === 'ARENAVICTORY' ? (<ArenaVictory />): ""}
          {display === 'ARENADEFEAT' ? (<ArenaDefeat />): ""}
          {display === 'HOME' ? (<Home />): ""}
        </Container>
    )
}

export default MainScreen
