import React, {useContext} from 'react'
import { PlayerContext } from '../App'
import { Tab, Tabs, Container, Button, Overlay, Popover, Table, OverlayTrigger } from 'react-bootstrap'
import { magicalEssenceList } from '../Data/EssenceMagicalList'
import { physicalEssenceList } from '../Data/EssencePhysicalList'
import { utilityEssenceList } from '../Data/EssenceUtilityList'
import Trait from './Trait'


const magicalTraits = magicalEssenceList.map((trait) =>
    <Trait trait={trait} />
)

const physicalTraits = physicalEssenceList.map((trait) =>
    <Trait trait={trait} />
)

const utilityTraits = utilityEssenceList.map((trait) =>
    <Trait trait={trait} />
)

function Essence() {
    const playerContext = useContext(PlayerContext)
    const { essence, essenceCategories, location} = playerContext.state
    return (
        <Container>
            <Tabs defaultActiveKey='magical' id='essenceList'>
                <Tab eventKey ='magical' title="Magical Traits">
                    <Table striped bordered hover size="sm">
                        <thead><tr>
                            <th>Name</th>
                            <th>Current</th>
                            <th>Required Trait</th>
                            <th>Max Level</th>
                            <th>Purchase?</th>
                        </tr></thead>
                        <tbody>{magicalTraits}</tbody>
                    </Table>    
                </Tab>
                <Tab eventKey ='physical' title="Physical Traits">
                    <Table striped bordered hover size="sm">
                        <thead><tr>
                            <th>Name</th>
                            <th>Current</th>
                            <th>Required Trait</th>
                            <th>Max Level</th>
                            <th>Purchase?</th>
                        </tr></thead>
                        <tbody>{physicalTraits}</tbody>
                    </Table>    
                </Tab>
                <Tab eventKey ='utility' title="Utility Traits">
                    <Table striped bordered hover size="sm">
                        <thead><tr>
                            <th>Name</th>
                            <th>Current</th>
                            <th>Required Trait</th>
                            <th>Max Level</th>
                            <th>Purchase?</th>
                        </tr>
                        </thead>
                        <tbody>{utilityTraits}</tbody>
                    </Table>      
                </Tab>
            </Tabs>
        </Container>
    )
}

export default Essence