import React, {useContext, useEffect, useReducer} from 'react'
import { PlayerContext } from '../App'
import { Container, Button, Table, CardDeck, Row, Alert, Col } from 'react-bootstrap'
import fightReducer from './FightReducer'
import PlayerCard from './PlayerCard'
import MonsterCard from './MonsterCard'
import Loot from './Loot'

export const FightContext = React.createContext()

const initFightState = {
    fightStatus: "Active",
    isArena: false,
    monster: {name: "", debuffs: []},
    playerMessage: "",
    monsterMessage: "Grrr",
    loot: []
}

function Fight() {
    const playerContext = useContext(PlayerContext)
    const [fightState, fightDispatch] = useReducer(fightReducer, initFightState)
    const { playerName, forestDepth, attackPower, magicPower, spellList, display, inArena } = playerContext.state

    useEffect(() => {startFight()}, [])

    function startFight () {
        //Set Player to inCombat
        playerContext.dispatch( {type: "FIELD", fieldName: "inCombat", payload: true })
        //Start the fight and load the monster
        fightDispatch({type: "INITIATE", forestDepth: forestDepth, playerData: playerContext})
    }

    return (
        <FightContext.Provider value={{fightState: fightState, fightDispatch: fightDispatch}}>
            {fightState.fightStatus === "Loot" ? 
            (<Loot />) 
            : 
            (<Container>
                    <Row>
                        <Col>
                            {fightState.playerMessage ? (
                                <Alert variant='primary'>{fightState.playerMessage}</Alert>
                            ) : ("")}
                        </Col>
                        <Col>
                            {fightState.monsterMessage ? (
                                <Alert variant='danger'>{fightState.monsterMessage}</Alert>
                            ) : ("")}
                        </Col>
                    </Row>
                    <CardDeck>
                        <PlayerCard />
                        <MonsterCard />
                    </CardDeck>   
            </Container>)}
        </FightContext.Provider>
    )
}

export default Fight