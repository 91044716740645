export const shrivenShopInventory = [
    {
      name: "Shriven Apple",
      type: "Consumable",
      subtype: "Heal",
      quantity: 99,
      minValue: 3,
      maxValue: 3,
      cost: 1,
      sellValue: 1,
    },
    {
      name: "Diamond",
      type: "Other",
      subtype: "Quest",
      quantity: 1,
      minValue: 3,
      maxValue: 3,
      cost: 1000,
      sellValue: 1,
    },
    {
      name: "Basic Axe",
      type: "Equipable",
      subtype: "mainHand",
      quantity: 1,
      quality: "Basic",
      minValue: 2,
      maxValue: 4,
      cost: 20,
      sellValue: 15
    },
    {
      name: "Basic Helmet",
      type: "Equipable",
      subtype: "head",
      quality: "Basic",
      quantity: 1,
      minValue: 3,
      maxValue: 3,
      cost: 30,
      sellValue: 10
    },
    {
      name: "Basic Guantlets",
      type: "Equipable",
      subtype: "hands",
      quantity: 1,
      quality: "Basic",
      minValue: 3,
      maxValue: 3,
      cost: 30,
      sellValue: 10
    },
  ]