import React, {useContext} from 'react'
import { PlayerContext } from '../App'
import { Table } from 'react-bootstrap'
import DisplayItem from './DisplayItem'

function DisplayBackpack() {
    const playerContext = useContext(PlayerContext)
    const { backpack } = playerContext.state

    const contents = backpack.map((item) => 
        <tr>
            <DisplayItem item={item} />
        </tr>
    )

    return (
        <React.Fragment> 
            <h3>Backpack</h3>
            <Table striped bordered hover size='sm'>
                <thead><tr>
                    <th>Items Name</th>
                    <th>Type</th>
                    <th>Quantity</th>
                </tr></thead>
                <tbody>{contents}</tbody>
            </Table>
        </React.Fragment>
    )
}

export default DisplayBackpack