import React, {useContext, useState, useEffect } from 'react'
import { PlayerContext } from '../App'
import { questList } from '../Data/QuestList'
import {Card, CardDeck, Container, Button, Table, Row, Alert} from 'react-bootstrap'
import DisplayQuest from './DisplayQuest'

const initialQuestState = {
    id: 0,
    name: "",
    description: "",
    status: "",
    type: "",
    objective: "",
    totalAmountNeeded: "",
    reward: [{name:"", quantity:"", type:""}]
}

function QuestBoard() {
    const playerContext = useContext(PlayerContext)
    const { playerName, currentQuests, currentTown } = playerContext.state
    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')
    const [questShown, setQuestShown] = useState(initialQuestState)
    const [questListToShow, setQuestListToShow] = useState(questList)

    //get quests that are eligible from questboard in the current town
    useEffect(() => {
        const newQuests = questList.filter((q => q.town === currentTown && q.questBoard === true))
        setQuestListToShow(newQuests)
        console.log("Quests = " + JSON.stringify(newQuests))
    }, [])
    
    
    function pickupQuest(e) {
        //verify that player not on quest already: 
        
        const found = currentQuests.filter(quest => quest.id === e.id)

        if (found.length > 0) {
            setErrorMessage("You're already on this quest!")
        } else {
            setMessage("Picked up quest: " + e.name)
            playerContext.dispatch({ type: "PICKUPQUEST", payload: { id: e.id, name: e.name, description: e.description, status: e.status, repeatable: e.repeatable,
                objective: e.objective, totalAmountNeeded: e.totalAmountNeeded, questType: e.questType, progress: 0, reward: e.reward, questBoard: e.questBoard, NPC: e.NPC, town: e.town}})
            setQuestShown(initialQuestState)   
        }
    }

    function showQuest(e) {
        
        setQuestShown(questListToShow[e])
        setErrorMessage("")
        setMessage("")
    }

    //on load - set the first quest as the quest detail pane
    //useEffect(() => {showQuest(0)}, []);

    function QuestItem(props) {
        const found = currentQuests.filter(quest => quest.id === props.quest.id)
        if (found.length > 0) {
            // console.log("already on quest: " + props.name)
            return null
        } else {
            return (<tr>
                <td>{props.quest.name}</td>
                <td>{props.quest.type}</td>
                <td>{props.quest.objective}</td>
                <td><Button size="sm" onClick={() => setQuestShown(props.quest)}>Details</Button></td>
            </tr>)
        }
    }

    const availableQuests = questListToShow.map((item) => 
        <QuestItem quest={item} />
    )

    const rewards = questShown.reward.map((item) => 
      <React.Fragment>
        {item.type === "Consumable" ? (<tr>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
            <td>-</td>
        </tr>) :  ("")}
        {item.subtype === "mainHand" ? (<tr>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
            <td>Damage: {item.minValue}-{item.maxValue}</td>
        </tr>) :  ("")}
        {item.type === "Equipable" && item.subtype !== "mainHand" ? (<tr>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
            <td>Armor: {item.minValue}</td>
        </tr>) :  ("")}
        {item.type === "Other" ? (<tr>
            <td>{item.name}</td>
            <td>{item.quantity}</td>
            <td>-</td>
        </tr>) :  ("")}
      </React.Fragment>
    )
    const showQuestDetails = (
        <React.Fragment>
            <DisplayQuest quest={questShown} />
            <Card.Footer>
                <Button 
                    size="sm"
                    onClick={() => pickupQuest(questShown)}
                >Pickup Quest</Button>
            </Card.Footer>
        </React.Fragment>
    )
    
    return (
        <Container>
            <Row>
                <h1 style={{textAlign:"center"}}>Quest Board</h1>
            </Row>
            <Row>
                <Table striped bordered hover size='sm'>
                    <thead><tr>
                        <th>Quest Name</th>
                        <th>Type</th>
                        <th>Objective</th>
                        <th>Show Details</th>
                    </tr></thead>
                    <tbody>{availableQuests}</tbody>
                </Table>
            </Row>
                <Card border="primary">
                    {questShown.id === 0 ? ("Select a Quest to see more details") : (showQuestDetails)}
                </Card>
            <Row>
                {errorMessage ? (<Alert variant='danger'  onClose={() => setErrorMessage("")} dismissible>{errorMessage}</Alert>) : ("")}
                {message ? (<Alert variant='success'  onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
            </Row><br />
            <Row>
                <Button onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'TOWN' })}>Go Back to Town</Button>
            </Row>
        </Container>
    )
}

export default QuestBoard
