import React, { useContext } from 'react'
import { PlayerContext } from '../App'
import { FightContext } from './Fight'
import { Card, Button } from 'react-bootstrap'

function FightDefeat() {
    const playerContext = useContext(PlayerContext)
    const fightContext = useContext(FightContext)

    const flee = () => {
        playerContext.dispatch({
            type: "FIELD",
            fieldName: "inCombat",
            payload: false})
    
        playerContext.dispatch({ type: "GOTO", payload: "TOWN" })
    }
    
    return (
        <Card.Footer>
            <Button 
                size='sm' 
                onClick={() => flee()}>
                Go Back to Town
            </Button>
        </Card.Footer>
    )
}

export default FightDefeat
