export const storyboards = [
    {
      id: 1,
      name: "Introduction",
      text:
        "You are out enjoying a nice day near the edge of the forest near your home town of Shriven. Then BOOM, a large purple explosion from somewhere in middle of the forest shakes you to your core.",
      storyBoardImg: "",
      previousBoard: "",
      nextBoard: 2,
      fight: false
    },
    {
      id: 2,
      name: "Kobold Attack",
      text:
        "As you gather your wits you realize you've been knocked to the ground by the blast. As you rise you see a nasty creature approaching you. You pick up a stick to defend yourself.",
      previousBoard: 1,
      nextBoard: "",
      fight: true
    },
    {
      id: 3,
      name: "Game Mechanics",
      text:
        "Instead of experience and levels, you gain 'essence' which can be spent on upgrades to your character. There is an increasing cost for each upgrade in a category. Different classes start at different levels, but are not limited in the categories they can upgrade (a warrior can upgrade magic power if they wish).",
      storyBoardImg: "",
      previousBoard: 2,
      nextBoard: "",
      fight: false
    },
]
  