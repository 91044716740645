import React, {useContext, useEffect, useState} from 'react'
import { PlayerContext } from '../App'
import { Container, Spinner, Row, Button, Table } from 'react-bootstrap'

function LoadCharacter() {
    const playerContext = useContext(PlayerContext)
    const { playerName, username } = playerContext.state
    const [toons, setToons] = useState([])
    const [isLoading, setIsLoading] = useState([true])

    function getChars() {
        const fetchURL = 'https://k15a1sgec8.execute-api.us-east-1.amazonaws.com/test/players?username=' + username 
        fetch(fetchURL)
            .then(response => response.json())
            .then(data => setToons(data))
            .then(setIsLoading(false))
    }

    useEffect(() => {getChars()}, [])
    
    function selectToon(toon) {
        playerContext.dispatch({ type: 'LOADCHARACTER', payload: toon})
    }

    function goBack() {
        playerContext.dispatch({ type: 'GOBACK', payload: 'FirstScreen'})
    }

    const charChoices = toons.map((toon) => 
        <tr>
            <td>{toon.playerName}</td>
            <td><Button size='sm' onClick={() => selectToon(toon)}>Select</Button></td>
        </tr>
    )

    
    return (
        <Container>
            <Button className="button" onClick={() => goBack()}>Go Back</Button>
            <Row><h4>Available Characters</h4></Row>
            {isLoading ? (<Spinner animation="border" variant="primary" />) : ("")}
            <Table striped bordered hover size="sm">
                <thead><tr>
                    <th>Name</th>
                    <th>Choose</th>
                </tr></thead>
                <tbody>
                    {charChoices}
                </tbody>
            </Table>
        </Container>
    )
}

export default LoadCharacter
