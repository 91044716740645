import React from 'react'
import DisplayBonus from './DisplayBonus'
import DisplayItemButton from './DisplayItemButton'

function DisplayItem(props) {
    const item = props.item
    const ironStyle = { backgroundColor: "lightgreen" }
    const steelStyle = { backgroundColor: "gold" }
    const mithrilStyle = { backgroundColor: "lightred" }

    //Displays Name, Type, Quantity, and Bonus
    return (
        <React.Fragment>
            {item.type === "Consumable" ? (<React.Fragment>
                    <td>{item.name}</td>
                    <td>{item.subtype}</td>
                    <td>{item.quantity}</td>
            </React.Fragment>) :  ("")}
            {item.type === "Equipable" ? (<React.Fragment>
                <DisplayItemButton item={item} />
                <td>{item.subtype}</td>
                <td>{item.quantity}</td>
            </React.Fragment>) :  ("")}
            {item.type === "Other" ? (<React.Fragment>
                <td>{item.name}</td>
                <td>{item.subtype}</td>
                <td>{item.quantity}</td>
            </React.Fragment>) :  ("")}
        </React.Fragment>
    )
}

export default DisplayItem
