import React from 'react'
import Card from 'react-bootstrap/Card'

function NonPlayer(props) {
    const nonPlayer = props.nonPlayer
    console.log(JSON.stringify(nonPlayer))
    return (
        <React.Fragment>
            <Card.Header>{nonPlayer.name}</Card.Header>
            <Card.Body>
                <Card.Img src={ require("../img/" + nonPlayer.img + ".png")}/>
                <Card.Text>{nonPlayer.desc}</Card.Text>
            </Card.Body>
        </React.Fragment>
    )
}

export default NonPlayer
