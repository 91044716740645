import React, {useContext, useState, useEffect} from 'react'
import { PlayerContext } from '../App'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

function DisplayToons() {
    const playerContext = useContext(PlayerContext)
    const { playerName, username } = playerContext.state
    const [toons, setToons] = useState([])
    const [isLoading, setIsLoading] = useState([true])
    
    async function getChars() {
        if (username) {
            try {
                const fetchURL = 'https://k15a1sgec8.execute-api.us-east-1.amazonaws.com/test/players?username=' + username
                fetch(fetchURL)
                    .then(response => response.json())
                    .then(data => setToons(data))
                    .then(console.log(JSON.stringify(toons)))
                    .then(setIsLoading(false))
            } catch (error) {
                console.log("Error: " + JSON.stringify(error))
            }
        }
    }

    useEffect(() => {getChars()}, [])
    useEffect(() => {getChars()}, [playerName])

    function selectToon(toon) {
        playerContext.dispatch({ type: 'LOADCHARACTER', payload: toon})
    }

    function newToon() {
        playerContext.dispatch({ type: 'RESTART'})
    }
    
    const charChoices = toons.map((toon) => 
        <tr>
            <td>{toon.playerName}</td>
            <td><Button size='sm' onClick={() => selectToon(toon)}>Select</Button></td>
        </tr>
    )

    return (
        <Table striped bordered hover size="sm">
            <thead><tr>
                <th>Name</th>
                <th>Choose</th>
            </tr></thead>
            <tbody>
                {charChoices}
                <tr>
                    <td>Create New Character:</td>
                    <td><Button size='sm' onClick={() => newToon()}>New Character</Button></td>
                </tr>
            </tbody>
        </Table>
    )
}

export default DisplayToons
