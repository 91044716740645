import React, { useContext } from 'react'
import { PlayerContext } from '../App'
import Convo from './Convo'

function BaseUpgradeComplete() {
    const playerContext = useContext(PlayerContext)

    return (
        <Convo convoToLoad="convoFirstBaseUpgrade"/>
    )
}

export default BaseUpgradeComplete
