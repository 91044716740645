import React, {useContext, useState, useEffect} from 'react'
import { PlayerContext } from '../App'
import Convo from './Convo'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import CardDeck from 'react-bootstrap/CardDeck'
import {ProgressBar, Spinner, Col, Alert} from 'react-bootstrap'

function ForestBase () {
    const playerContext = useContext(PlayerContext)
    const { currentTown, forestDepth, maxForestDepth, wood, stone, essence, currentQuests } = playerContext.state
    const [message, setMessage] = useState("")
    const [isBuilding, setIsBuilding] = useState(false)
    const [baseState, setBaseState] = useState("initial")


    useEffect(() => {
        const stoneQuest = currentQuests.filter(q => q.id === 5)
        const woodQuest = currentQuests.filter(q => q.id === 6)

        if (stoneQuest[0] && !woodQuest[0]) {
            if (stoneQuest[0].status === "Active") {
                setBaseState("PendingStone")
            } else {
                setBaseState("NeedWoodQuest")
            }
        } else if (stoneQuest[0] && woodQuest[0]) {
            if (woodQuest[0].status === "Active") {
                setBaseState("PendingWood")
            } else {
                setBaseState("Full")
            }
        } else {
            setBaseState("NeedStoneQuest")
        }
    }, [])

    return (
        <Container>
            {baseState === "NeedStoneQuest" && (<Convo convoToLoad="convoStartBase"/>)}
            {baseState === "PendingStone" && 
                <Card>
                    <Card.Header>Finish Stone Gathering Quest</Card.Header>
                    <Card.Body>Purchase a pickax from Haven and turn in the quest. Then return here.</Card.Body>
                    <Card.Footer>
                            <Button onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'FOREST' })}>Go Back to Forest</Button>
                    </Card.Footer>
                </Card>
            }
            {baseState === "NeedWoodQuest" && (<Convo convoToLoad="convoSecondBase"/>)}
            {baseState === "PendingWood" && 
                <Card>
                    <Card.Header>Finish Wood Gathering Quest</Card.Header>
                    <Card.Body>Purchase a woodax from Thornbrook and turn in the quest. Then return here.</Card.Body>
                    <Card.Footer>
                            <Button onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'FOREST' })}>Go Back to Forest</Button>
                    </Card.Footer>
                </Card>
            }
            {baseState === "Full" &&
                <React.Fragment>
                    <Row><Card>
                        <Card.Title><strong>Forest Base Level {maxForestDepth}</strong></Card.Title>
                        <Card.Body>
                            <Card.Text>This base allows you to enter the 2nd level of the forest</Card.Text>
                            <Card.Img fluid src={require("../img/Base2.png")}/>
                        </Card.Body>
                        <Card.Footer>
                            <Button onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'FOREST' })}>Go Back to Forest</Button>
                        </Card.Footer>
                    </Card></Row><br />
                    <Row>
                        <Col>
                            {message ? (<Alert variant='success'  onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </Container>
    )
}

export default ForestBase
