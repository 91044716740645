export const questList = [
    {
      id: 1,
      name: "Green Apples for Bob",
      description: "Bob in Shriven needs Green Apples for his famous apple pies. The problem is he can't venture into the woods to pick them up.  Please retrieve 10 apples for him.",
      status: "Available",
      repeatable: true,
      questBoard: true,
      nonPlayer: "Bob",
      town: "Shriven",
      objective: "Green Apple",
      questType: "collection",
      totalAmountNeeded: 1,
      progress: 0,
      reward: [
          {
              name: "Gold",
              type: "Other",
              quantity: 10
          },
          {
              name: "Apple Pie",
              type: "Consumable",
              subtype: "Heal",
              quantity: 2,
              minValue: 10,
              maxValue: 10,
              cost: 5,
              sellValue: 3,
          }
      ]
    },
    {
        id: 2,
        name: "Kobolds Must Die!",
        description: "Mayor Billy has asked that the Kobolds thinned out of the forest. They are worse then ever after the incident, the town needs help before being overwhelmed.",
        status: "Available",
        repeatable: false,
        questBoard: true,
        nonPlayer: "Mayor Billy",
        town: "Shriven",
        objective: "Kobold",
        questType: "kill",
        totalAmountNeeded: 5,
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 25
            },
            { 
                name: "Kobold Kickers",
                type: "Equipable",
                subtype: "feet",
                quantity: 1,
                quality: "Basic",
                minValue: 5,
                maxValue: 5,
                cost: 50,
                sellValue: 20
            }
        ]
      },
      {
        id: 3,
        name: "Haven Tom Likes Apples Too",
        description: "Bob in Shriven needs Green Apples for his famous apple pies. The problem is he can't venture into the woods to pick them up.  Please retrieve 10 apples for him.",
        status: "Available",
        nonPlayer: "Tom",
        repeatable: false,
        questBoard: true,
        town: "Haven",
        objective: "Green Apple",
        questType: "collection",
        totalAmountNeeded: "1",
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 10
            },
            {
                name: "Apple Pie",
                type: "Consumable",
                subtype: "Heal",
                quantity: 2,
                minValue: 10,
                maxValue: 10,
                cost: 5,
                sellValue: 3,
            }
        ]
      },
      {
        id: 4,
        name: "Welcome to Shirven",
        description: "Stop! You've got the purple glow on you, what happened?? What caused the explosion?" + 
        " Of course you don't know, but we need to find out. You look like you can handle yourself. Enter the forest and kill some Vampire Bats to study the essence they are infected with.",
        status: "Available",
        questBoard: false,
        nonPlayer: "Mayor Billy",
        repeatable: false,
        town: "Shriven",
        objective: "Vampire Bat",
        questType: "kill",
        totalAmountNeeded: 2,
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 10
            },
            {
                name: "Iron Shield of Fire",
                type: "Equipable",
                subtype: "offHand",
                quantity: 1,
                quality: "Iron",
                bonus: {
                    name: "Fire", 
                    abilities: ["strength"], 
                    abilityMod: 1
                },
                minValue: 5,
                maxValue: 5,
                cost: 20,
                sellValue: 2
            },
            {
                name: "Basic Axe",
                type: "Equipable",
                subtype: "mainHand",
                quantity: 1,
                quality: "Basic",
                minValue: 5,
                maxValue: 5,
                cost: 20,
                sellValue: 2
            }
        ]
      },
      {
        id: 5,
        name: "Gather stone for the Forest Base",
        description: "First we need to gather stone to make a sturdy foundation for the forest base. Get a pickax from Haven, and then come back to me for the next step.",
        status: "Available",
        questBoard: false,
        repeatable: false,
        nonPlayer: "Mayor Billy",
        town: "Shriven",
        objective: "Pickax",
        questType: "collection",
        totalAmountNeeded: 1,
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 10
            },
        ]
      },
      {
        id: 6,
        name: "Gather wood for the Forest Base",
        description: "Now, we need a special woodax made in Thornbrook to cut down trees that are think enough to build a sturdy base. Come back once you've finsihed this task.",
        status: "Available",
        questBoard: false,
        repeatable: false,
        nonPlayer: "Mayor Billy",
        town: "Shriven",
        objective: "Woodax",
        questType: "collection",
        totalAmountNeeded: 1,
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 10
            },
        ]
      },
      {
        id: 7,
        name: "Thornbrook likes Pears better",
        description: "Unlike the slobs in Haven and Shriven, we eat Pear Pie here. Collect me a few pears and I'll show you how good they are!",
        status: "Available",
        nonPlayer: "First Citizen PearLover",
        repeatable: true,
        questBoard: true,
        town: "Thornbrook",
        objective: "Pear",
        questType: "collection",
        totalAmountNeeded: "5",
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 10
            },
            {
                name: "Pear Pie",
                type: "Consumable",
                subtype: "Heal",
                quantity: 2,
                minValue: 10,
                maxValue: 10,
                cost: 5,
                sellValue: 3,
            }
        ]
      },
      {
        id: 8,
        name: "Killing in the Name of...",
        description: "Priest Jimmy has asked help exterminating the snakes that are growing larger due to the event. He says these snakes are an existential threat to our culture, tempting us to gather more power.",
        status: "Available",
        repeatable: false,
        questBoard: true,
        nonPlayer: "Priest Jimmy",
        town: "Shriven",
        objective: "Abom Snake",
        questType: "kill",
        totalAmountNeeded: 5,
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 25
            },
            {
                name: "Snake Leather Shoulders",
                type: "Equipable",
                subtype: "shoulders",
                quantity: 1,
                quality: "Steel",
                bonus: {
                    name: "Agility", 
                    abilities: ["agility"], 
                    abilityMod: 5
                },
                minValue: 10,
                maxValue: 10,
                cost: 200,
                sellValue: 50
            },
        ]
      },
      {
        id: 9,
        name: "Trolls Be A Menace To Society",
        description: "Priest Jimmy wants you to eradicate all the trolls.",
        status: "Available",
        repeatable: false,
        questBoard: true,
        nonPlayer: "Priest Jimmy",
        town: "Shriven",
        objective: "Troll",
        questType: "kill",
        totalAmountNeeded: 5,
        progress: 0,
        reward: [
            {
                name: "Gold",
                type: "Other",
                quantity: 25
            },
            {
                name: "Steel Troll Shield of Glacial",
                type: "Equipable",
                subtype: "shoulders",
                quantity: 1,
                quality: "Steel",
                bonus: {
                    name: "Agility", 
                    abilities: ["agility", "constitution"], 
                    abilityMod: 2
                },
                minValue: 10,
                maxValue: 10,
                cost: 200,
                sellValue: 50
            },
        ]
      },
  ]