import React, {useContext, useState, useEffect } from 'react'
import { PlayerContext } from '../App'
import { Alert, Button, Card, CardDeck, Row, Col} from 'react-bootstrap'
import { questList } from '../Data/QuestList'
import DisplayQuest from './DisplayQuest'


function Convo(props) {
    const playerContext = useContext(PlayerContext)
    const { playerName, convoWith, essence, currentQuests } = playerContext.state
    const [message, setMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [quest, setQuest] = useState({name:"", description:"", reward:[{name:"", type:"", quantity:""}], objectives:[{name:"", type:"", totalAmountNeeded:""}]})
    const [place, setPlace] = useState("Town")

    //update useEffect to get monster on screen load
    useEffect(() => {
        if (props.convoToLoad === "convoFirstMayor"){ 
            const newQuest = questList.filter((q => q.id === 4))
            setQuest(newQuest[0])
        } else if (props.convoToLoad === "convoStartBase") {
            const newQuest = questList.filter((q => q.id === 5))
            setQuest(newQuest[0])
        } else if (props.convoToLoad === 'convoSecondBase') {
            const newQuest = questList.filter((q => q.id === 6))
            setQuest(newQuest[0])
        } else {
            console.log("I found nothing")
        }
    }, [])

    function pickupQuest() {
        //verify that player not on quest already: 
        const found = currentQuests.filter(q => q.id === quest.id)

        if (found.length > 0) {
            setErrorMessage("You're already on this quest!")
        } else {
            setMessage("Picked up quest: " + quest.name)
            playerContext.dispatch({ type: "PICKUPQUEST", payload: { ...quest, status: "Active" }})
            if (quest.id === 4) {
                playerContext.dispatch({ type: 'FIELD', fieldName: 'forestDepth', payload: 1})
            } else if (quest.id === 5 || quest.id === 6) {
                playerContext.dispatch({ type: 'GOTO', payload: 'FOREST'})
            }
        }
    }

    return (
        <React.Fragment>
            <CardDeck>
                <Card>
                    <DisplayQuest quest={quest} />
                    <Card.Footer>
                        <Button size='sm' onClick={() => pickupQuest()}>Accept Quest</Button>
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Header>Mayor Billy</Card.Header>
                    <Card.Body>
                        <Card.Img src={ require("../img/Priest.png")}/>
                    </Card.Body>
                </Card>
            </CardDeck>
            <br />
            <Row><Col>
                {message ? (<Alert variant="success" onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
                {errorMessage ? (<Alert variant="danger" onClose={() => setErrorMessage("")} dismissible>{errorMessage}</Alert>) : ("")}
            </Col></Row>
        </React.Fragment>
    )
}

export default Convo
