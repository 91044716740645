export const physicalEssenceList = [
  {
    name: "Strength",
    sysName: "strength",
    toolTip:
      "Improves physical attacks. Future: Improves how much you can carry?",
    maxLevel: 20,
    disabled: false,
  },
  {
    name: "Constitution",
    sysName: "constitution",
    toolTip: "Improves the amount of max health you have.",
    maxLevel: 20,
    disabled: false,
  },
  {
    name: "Agility",
    sysName: "agility",
    toolTip:
      "Improves quickness and dexeterity. At high enough level allows for crits and dodge. Each point in agility increases critical strike damage.",
    maxLevel: 20,
    disabled: false,
  },
  {
    name: "Dodge",
    sysName: "dodge",
    toolTip: "Grants the ability to dodge attacks. Each point increases the amount of dodge.",
    maxLevel: 20,
    preReq: { 
      name: "Agility",
      sysName: "agility",
      level: 1 
    },
    disabled: false,
  },
  {
    name: "Critical Strike Chance",
    sysName: "critChance",
    toolTip: "Grants the ability to get critical attacks. Each point increases the chance of a critical strike.",
    maxLevel: 20,
    preReq: { 
      name: "Agility",
      sysName: "agility",
      level: 5 
    },
    disabled: false,
  },
];
