import React, {useContext} from 'react'
import { PlayerContext } from '../App'
import { Button, Overlay, Popover, OverlayTrigger } from 'react-bootstrap'

function Trait(props) {
    const playerContext = useContext(PlayerContext)
    const { essence, essenceCategories } = playerContext.state
    
    const currentTrait = essenceCategories.filter(category => category.name === props.trait.sysName)

    let currentPreReqLevel = 0
    let requiredTraitLevel = 0

    if (props.trait.preReq) {
        const preReqTrait = essenceCategories.filter(category => category.name === props.trait.preReq.sysName)
        currentPreReqLevel = preReqTrait[0].level
        requiredTraitLevel = props.trait.preReq.level
    }

    //console.log(preReqTrait[0].name + ": current trait level: " + preReqTrait[0].level + " required trait level: " + currentPreReqLevel)

    function handleClick () {
        const payload = {
            name: props.trait.sysName,
            level: currentTrait[0].level + 1,
            costToUpgrade: currentTrait[0].costToUpgrade * 2
        }
        playerContext.dispatch({ type: "UPGRADETRAIT", traitCost: currentTrait[0].costToUpgrade, payload: payload });
    }
    return (
        <OverlayTrigger
            placement='top'
            delay={{show: 250, hide: 400}}
            overlay={        
                <Popover id={`popover-${props.trait.name}`}>
                    <Popover.Title as="h3">{props.trait.name}</Popover.Title>
                    <Popover.Content>
                        {props.trait.toolTip}
                    </Popover.Content>
                </Popover>
            }
        >
            <tr>
                <td>{props.trait.name}</td>
                <td>{currentTrait[0].level}</td>
                <td>{props.trait.preReq ? (
                    <React.Fragment>
                        {props.trait.preReq.name}: {props.trait.preReq.level}
                    </React.Fragment>) : ("")}</td>
                <td>{props.trait.maxLevel}</td>
                <td><Button 
                    disabled={
                        essence < currentTrait[0].costToUpgrade || 
                        props.trait.disabled || 
                        currentTrait[0].level === props.trait.maxLevel ||
                        currentPreReqLevel < requiredTraitLevel
                    }
                    onClick={() => handleClick()}
                    size='sm'>Upgrade ({currentTrait[0].costToUpgrade})
                </Button></td>
            </tr>
        </OverlayTrigger>
    )
}

export default Trait
