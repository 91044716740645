/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2244d069-68a2-427d-a761-8b98235c3c4d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mUjqyuMpS",
    "aws_user_pools_web_client_id": "111nf1418e28t4qsvu35kea4eb",
    "oauth": {}
};


export default awsmobile;
