import React from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Carousel from 'react-bootstrap/Carousel'

function Home() {

    return (
        <Carousel>
        <Carousel.Item>
            <Card>
                <Card.Header>Monster 1</Card.Header>
                <Card.Text>Yum</Card.Text>
                <Card.Footer><Button>Attack</Button></Card.Footer>
            </Card>
        </Carousel.Item>
        <Carousel.Item>
            <Card>
                <Card.Header>Monster 2</Card.Header>
                <Card.Text>Yummy</Card.Text>
                <Card.Footer><Button>Attack</Button></Card.Footer>
            </Card>
        </Carousel.Item>
        <Carousel.Item>
            <Card>
                <Card.Header>Monster 1</Card.Header>
                <Card.Text>Yummy In My Tummy</Card.Text>
                <Card.Footer><Button>Attack</Button></Card.Footer>
            </Card>
        </Carousel.Item>
        </Carousel>
    )
}

export default Home
