import React, {useContext} from 'react'
import { PlayerContext } from '../App'
import Button from 'react-bootstrap/Button'
import { CardDeck, Card, Form } from 'react-bootstrap'

function FirstScreen() {
    const playerContext = useContext(PlayerContext)
    const { playerName } = playerContext.state

    function handleCreateClick () {
        playerContext.dispatch({ type: 'FIELD', fieldName: 'isCreateCharacter', payload: true})
        playerContext.dispatch({ type: 'FIELD', fieldName: 'isLoadScreen', payload: false})
    }

    function handleLoadClick () {
        playerContext.dispatch({ type: 'FIELD', fieldName: 'isLoadCharacter', payload: true})
        playerContext.dispatch({ type: 'FIELD', fieldName: 'isLoadScreen', payload: false})
    }

    return (
        <CardDeck style={{ textAlign: 'center' }}>
            <Card border="primary">
                <Card.Title>Create a New Character</Card.Title>
                <Card.Body>
                    <Card.Text>Start a new character from scratch. Each account can have 10 characters.</Card.Text>
                </Card.Body>
                <Card.Footer>
                    <Form>
                        <Form.Group controlId="formPlayerName">
                            <Form.Label>Character Name:</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Enter Character Name" 
                            value={playerName}
                            onChange={(e) =>
                                playerContext.dispatch({
                                type: 'FIELD',
                                fieldName: 'playerName',
                                payload: e.currentTarget.value,
                                })}
                            />
                        </Form.Group>
                        <Button 
                            variant="primary" 
                            type="submit"
                            disabled={!playerName}
                            onClick={() => playerContext.dispatch({ type: 'CREATECHARACTER' })}>
                            Create
                        </Button>
                    </Form>
                </Card.Footer>
            </Card>
            <Card border="primary">
                <Card.Title>Load an Existing Character</Card.Title>
                <Card.Body>
                    <Card.Text>Load a saved character from your account.</Card.Text>
                </Card.Body>
                <Card.Footer>
                    <Button className="button" onClick={() => handleLoadClick()} >Load</Button>
                </Card.Footer>
            </Card>
        </CardDeck>
    )
}

export default FirstScreen
