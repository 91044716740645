import React, {useContext, useState} from 'react'
import { PlayerContext } from '../App'
import { Alert, Button, Spinner, Row, Container, CardDeck, Card, Col} from 'react-bootstrap'


function Gather() {
    const playerContext = useContext(PlayerContext)
    const { playerName,backpack, stone, wood } = playerContext.state
    const [isGathering, setIsGathering] = useState(false)
    const [progressAmount, setProgressAmount] = useState(0)
    const [message, setMessage] = useState("")
    const [errMessage, setErrMessage] = useState("")

    const hasPickax = backpack.filter((item) => item.name === "Pickax" )
    const hasWoodax = backpack.filter((item) => item.name === "Woodax")

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    
    async function handleClick(choice) {
        setIsGathering(true)
        for (let i = 0; i < 10; i++) {
            await sleep(100)
            setProgressAmount(i*10)
        }
        setIsGathering(false)
        setMessage("Gathered " + choice)
        if (choice === "stone") {
            playerContext.dispatch({ type: "FIELD", fieldName: "stone", payload: stone+20})
        } else {
            playerContext.dispatch({ type: "FIELD", fieldName: "wood", payload: wood+20})
        }
        //add code to actually add stuff
    }

    return (
        <Container>
            <h3>Gather resources for your base.</h3>
            <p>
                Buy a pickax in Haven to gather stone.
                <br />Buy a wood axe in Thornbrook gather wood.
            </p>
            <CardDeck>
                <Card>
                    <Card.Header><strong>Wood</strong> (Currently have: {wood})</Card.Header>
                    <Card.Body>
                        <Card.Text>It's wood...you know...like from trees. Shouldn't be hard to find in a forest.</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        {isGathering ? (<Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Gathering ({progressAmount}%)...
                        </Button>) : (
                            <Button 
                            disabled={isGathering || hasWoodax.length < 1}
                            onClick={() => handleClick("wood")}>Gather Wood</Button>
                        )}
                    </Card.Footer>
                </Card>
                <Card>
                    <Card.Header><strong>Stone</strong> (Currently Have: {stone})</Card.Header>
                    <Card.Body>
                        <Card.Text>Big Rocks - Crush them and profit</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        {isGathering ? (<Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Gathering ({progressAmount}%)...
                        </Button>) : (
                            <Button 
                            disabled={isGathering || hasPickax.length < 1}
                            onClick={() => handleClick("stone")}>Gather Stone</Button>
                        )}
                    </Card.Footer>
                </Card>
            </CardDeck><br/>
            <Row>
                <Col>
                {message ? (<Alert variant="success" onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
                {errMessage ? (<Alert
                    variant="danger"
                    onClose={() => setErrMessage("")}
                    dismissible
                >{errMessage}</Alert>) : ("")}
                </Col>
            </Row>
        </Container>
    )
}

export default Gather
