import React, { useState,  useContext } from 'react'
import { PlayerContext } from '../App'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Button, Form, Row, Tab, Tabs, Alert, Col} from 'react-bootstrap'
import { Auth } from 'aws-amplify'

const initialFormState = {
    username: "", password: "", newUsername: "", newPassword: "", email: "", authCode: "", formType: 'signUp'
}

function Login() {
    const playerContext = useContext(PlayerContext)
    const [formState, updateFormState] = useState(initialFormState)
    const [errorMessage, setErrorMessage] = useState(null)
    const [message, setMessage] = useState(null)

    function onChange(e) {
        e.persist()
        updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }))
    }

    async function signUp() {
        const { username, email, password } = formState
        try {
            await Auth.signUp( { username, password, attributes: { email } })
            updateFormState(() => ({ ...formState, formType: "confirmSignUp" }))
        } catch (error) {
            setErrorMessage("Error Signing Up: " + error['message'])
        }
    }

    async function confirmSignUp() {
        const { username, authCode } = formState
        try {
            await Auth.confirmSignUp( username, authCode )
            updateFormState(() => ({ ...formState, formType: "signIn" }))
        } catch (error) {
            setErrorMessage("Auth Code Error: " + error['message'])
        }
    }

    async function signIn() {
        const { username, password } = formState
        try {
            const { user } = await Auth.signIn( username, password )
            //Need to change to LOGIN reducer (update isLoggedIn, username, set display to CharChoose)
            localStorage.clear()
            playerContext.dispatch({ type: "LOGIN", payload: {username: username } })
        } catch (error) {
            setErrorMessage("Error Signing In: " + error['message'])
        }
    }

    async function forgotPassword() {
        setMessage("Clicked Forgot Password")
        // const { username, password } = formState
        // try {
        //     const { user } = await Auth.signIn( username, password )
        //     //Need to change to LOGIN reducer (update isLoggedIn, username, set display to CharChoose)
        //     playerContext.dispatch({ type: "FIELD", fieldName: "isLoggedIn", payload: true })
        // } catch (error) {
        //     setErrorMessage("Error Signing In: " + error['message'])
        // }
    }

    return (
        <Container>
            { formState.formType === 'signUp' || formState.formType === 'signIn' ? (
                <React.Fragment>
                    <Row><h3>Please Login or Register a New Account.</h3></Row>
                    <Tabs defaultActiveKey='login' id='loginscreen'>
                        <Tab eventKey ='login' title="Login">
                            <Form>
                                <Form.Group controlId="formLoginUsername">
                                    <Form.Label>Username:</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Enter Username" 
                                        name="username"
                                        onChange={onChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formLoginPass">
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Password" 
                                        name="password"
                                        onChange={onChange}
                                    />
                                </Form.Group>
                                <Row><Col>
                                    <Button 
                                        disabled={!formState.username  || !formState.password}
                                        onClick={signIn}>Login</Button>
                                </Col>
                                <Col>
                                    <Button
                                        variant='link' 
                                        onClick={forgotPassword}>Forgot Password</Button>
                                </Col></Row>
                            </Form>  
                        </Tab>
                        <Tab eventKey='register' title="Register">
                            <Form>
                                <Form.Group controlId="formRegUsername">
                                    <Form.Label>Username:</Form.Label>
                                    < Form.Control 
                                        type="text" 
                                        placeholder="Enter Username" 
                                        name="username"
                                        onChange={onChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formRegEmail">
                                    <Form.Label>Email:</Form.Label>
                                    <Form.Control 
                                        type="email" 
                                        placeholder="Enter Email Address" 
                                        name="email"
                                        onChange={onChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formRegPassword"> 
                                    <Form.Label>Password:</Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder="Enter Password" 
                                        name="password"
                                        onChange={onChange}
                                    />
                                </Form.Group>
                                <Button
                                    disabled={!formState.username  || !formState.password || !formState.email} 
                                    onClick={signUp}>Register New Account</Button>
                            </Form>     
                        </Tab>
                    </Tabs>
                </React.Fragment>
            ) : (
                <Form>
                    <Form.Group controlId="formAuthCode">
                        <Form.Label>Authorization Code:</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter Auth Code" 
                            name="authCode"
                            onChange={onChange}
                        />
                    </Form.Group>
                    <Button 
                        disabled={!formState.authCode}
                        onClick={confirmSignUp}>Verify Account</Button>
                </Form>     
            )}
            <br/><Row>
                {message ? (<Alert variant='success'  onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
                {errorMessage ? (<Alert variant='danger'  onClose={() => setErrorMessage("")} dismissible>{errorMessage}</Alert>) : ("")}
            </Row>
        </Container>
      )
  }
  
  export default Login