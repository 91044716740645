import React, {useContext, useState} from 'react'
import { PlayerContext } from '../App'
import { FightContext } from './Fight'
import FightVictory from './FightVictory'
import FightDefeat from './FightDefeat'
import ArenaVictory from './ArenaVictory'
import ArenaDefeat from './ArenaDefeat'
import { Card, Button, DropdownButton, Dropdown, ButtonGroup, ProgressBar, Modal, Spinner } from 'react-bootstrap'

function PlayerCard() {
    const playerContext = useContext(PlayerContext)
    const { playerName, currentHealth, maxHealth, spellList, forestDepth, currentMana, maxMana, display, inArena } = playerContext.state

    const fightContext = useContext(FightContext)
    const { fightStatus, playerMessage, monsterMessage, isArena } = fightContext.fightState
    
    const [spellName, setSpellName] = useState("")
    const [spell, setSpell] = useState()
    const [hasMana, setHasMana] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [progressAmount, setProgressAmount] = useState(0)
    const [isAttacking, setIsAttacking] = useState(false)

    const handleClose = () => setModalShow(false)

    function spellPreCheck (spell) {
        setSpellName(spell.name)
        setSpell(spell)
    }

    function spellFinalCheck () {
        if (currentMana >= spell.manaCost) {
            fightContext.fightDispatch({type:"ATTACK", attackType: "spell", spell: spellName, playerData: playerContext})
        } else {
            setModalShow(true)
        }
    }

    const spells = spellList.map((spell) => (
        <Dropdown.Item
          name="spellToCast"
          value={spell.name}
          onSelect={() => {spellPreCheck(spell)}}>{spell.name}
        </Dropdown.Item>
      ))

    const flee = () => {
        playerContext.dispatch({
            type: "FIELD",
            fieldName: "inCombat",
            payload: false})
        if (forestDepth < 1) {
            playerContext.dispatch({ type: "GOTO", payload: "TOWN" });
        } else {
            playerContext.dispatch({ type: "GOTO", payload: "FOREST" });
        }
    }
    
    function handleEndRunClick() {
        playerContext.dispatch({ type: 'GOTO', payload: "ARENADEFEAT" })
    }

    function handleNextMatchClick() {
        playerContext.dispatch({ type: 'GOTO', payload: "ARENAVICTORY" })
    }

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    async function handleClick(attackType) {
        setIsAttacking(true)
        fightContext.fightDispatch( {type: "SETMESSAGES", playerMessage: "Attacking...", monsterMessage:"Attacking..."})
        for (let i = 0; i < 10; i++) {
            await sleep(100)
            setProgressAmount(i*10)
        }
        setIsAttacking(false)
        if (attackType === "weapon") {
            fightContext.fightDispatch({type:"ATTACK", attackType: "weapon", playerData: playerContext})
        } else if (attackType === "spell") {
            spellFinalCheck()
        }
    }

    return (
        <React.Fragment>
            {modalShow ? (
                <Modal show={modalShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Insufficient Mana</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>You tried to cast: {spellName} which costs {spell.manaCost}. You only have {currentMana} mana.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : ("")}
        <Card border="primary">
            <Card.Header>{playerName}</Card.Header>
            <Card.Img src={require("../img/Player.png")}/>
            <Card.Body>
                <Card.Text>
                    <b>Health:</b>
                    <ProgressBar 
                        label={currentHealth + "/" + maxHealth}
                        variant='success' 
                        animated now={(currentHealth/maxHealth) * 100} />
                    <b>Mana:</b>
                    <ProgressBar 
                        label={currentMana + "/" + maxMana}
                        animated now={(currentMana/maxMana) * 100} />
                </Card.Text>
            </Card.Body>
            {fightStatus === "Victory" ? (<FightVictory />) : ("")}
            {fightStatus === "Defeat" ? (<FightDefeat />) : ("")}
            {fightStatus === "ArenaVictory" ? (<Button size='sm' 
                onClick={() => handleNextMatchClick() }>
                Round Complete
                </Button>): (null)}
            {fightStatus === "ArenaDefeat" ? (<Button size='sm' 
                onClick={() => handleEndRunClick() }>
                Finish Arena Run
                </Button>): (null)}
            {fightStatus === "Active" ? (
                <Card.Footer>
                    <ButtonGroup style={{ padding: "1rem" }}>
                    {isAttacking ? (<Button variant="primary" disabled>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            />
                            Attacking ({progressAmount}%)...
                        </Button>) : (
                        <Button 
                            size='sm' 
                            onClick={() => handleClick("weapon") }>
                            Attack
                        </Button>)}

                    {isAttacking ? (null) : (
                        <React.Fragment>
                            <DropdownButton
                                variant="success"
                                id="spell-dropdown"
                                title="Spells"
                            >{spells}
                            </DropdownButton>
                            <Button 
                                size='sm' 
                                variant="success"
                                disabled={!spellName}
                                onClick={() => handleClick("spell") }>
                                Cast {spellName}
                            </Button>
                        </React.Fragment>)}
                        {isAttacking || inArena ? (null) : (
                        <Button 
                            size='sm' 
                            variant="warning"
                            onClick={() => flee() }>
                            Flee
                        </Button>)}
                    </ButtonGroup>
                </Card.Footer>
            ) : ("")}
        </Card>
        </React.Fragment>
    )
}

export default PlayerCard
