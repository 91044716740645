import React, { useReducer, useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row} from 'react-bootstrap'
import Login from './Components/Login'
import MainScreen from './Components/MainScreen'
import Header from './Components/Header'
import FirstScreen from './Components/FirstScreen'
import LoadCharacter from './Components/LoadCharacter'
import { initialState } from './Data/InitialState'
import stateReducer from './Components/StateReducer'

export const PlayerContext = React.createContext()

function App() {
  const [state, dispatch] = useReducer(stateReducer, initialState)
  const { isLoginScreen, playerName, isCreated, isLoadCharacter, isLoadScreen , display, inCombat} = state

  useEffect (() => {
    const data = localStorage.getItem("containedRPG-localState")
    if (data) {
      const parsedData = JSON.parse(data)
      dispatch({ type: 'LOADSTATE', payload: parsedData})
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("containedRPG-localState", JSON.stringify(state))
  }, [display])

  return (
    <PlayerContext.Provider value={{state: state, dispatch: dispatch}}>
      <div>
      <Header />
          {isCreated ? (
            <React.Fragment>
              <MainScreen />
            </React.Fragment>
          ) : (
            <Container style={{ padding: '2rem' }}>
              <Row>
                {isLoginScreen ? (<Login />) : ""}
                {isLoadScreen ? (<FirstScreen />) : ""}
                {isLoadCharacter ? (<LoadCharacter />) : ""}
              </Row>
            </Container>
          )}
      </div>
    </PlayerContext.Provider>
  )
}

export default App
