export const magicalEssenceList = [
  {
    name: "Intelligence",
    sysName: "intelligence",
    toolTip: "Improves magical attacks and opens up spells at higher levels",
    maxLevel: 20,
    disabled: false,
  },
  {
    name: "Spirit",
    sysName: "spirit",
    toolTip: "Increases the amount of Mana the player has.",
    maxLevel: 20,
    disabled: false,
  },
  {
    name: "Spell: Magic Missle",
    sysName: "magicMissle",
    toolTip:
      "Grants the user the ability to cast small magical projectile from their hand",
    maxLevel: 1,
    preReq: { 
      name: "Intelligence",
      sysName: "intelligence",
      level: 1 
    },
    disabled: false,
  },
  {
    name: "Spell: Heal",
    sysName: "heal",
    toolTip:
      "Grants the user the ability to cast a healing spell on themselves",
    maxLevel: 1,
    preReq: { 
      name: "Intelligence",
      sysName: "intelligence",
      level: 2 
    },
    disabled: false,
  },
  {
    name: "Spell: Fireball",
    sysName: "fireball",
    toolTip: "Grants the ability to cast a fireball at the enemey",
    maxLevel: 1,
    preReq: { 
      name: "Intelligence",
      sysName: "intelligence",
      level: 5 
    },
    disabled: false,
  },
  {
    name: "Magic Critical Chance",
    sysName: "magicCritChance",
    toolTip:
      "Grants the ability to get critical attacks with spells, doubling damage.",
    maxLevel: 10,
    preReq: { 
      name: "Intelligence",
      sysName: "intelligence",
      level: 5 
    },
    disabled: false,
  },
];
