import React, { useContext, useState, useEffect } from "react"
import { PlayerContext } from "../App"
import { shrivenShopInventory } from "../Data/Towns/Shriven/ShopInventory"
import { havenShopInventory } from "../Data/Towns/Haven/ShopInventory"
import { thornbrookShopInventory } from "../Data/Towns/Thornbrook/ShopInventory"
import { Container, OverlayTrigger, Table, Button, Row, Alert, Col, Tooltip } from "react-bootstrap"
import DisplayItem from "./DisplayItem"

function Shop() {
  const playerContext = useContext(PlayerContext)
  const { playerName, backpack, gold, mainHand, head, feet, hands, offHand, pants,
    chest, shoulders, currentTown, currentQuests } = playerContext.state
  const [message, setMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [shopInventory, setShopInventory] = useState(shrivenShopInventory)

  //update useEffect to get monster on screen load
  useEffect(() => {
    switch (currentTown) {
      case "Shriven":
        setShopInventory(shrivenShopInventory)
        break
      case "Haven":
        setShopInventory(havenShopInventory)
        break
      case "Thornbrook":
        setShopInventory(thornbrookShopInventory)
        break
    }}, [])

  function buyItem(e) {
    setErrorMessage("")
    setMessage("")
    let count = 1

    //check to see if can purchase first
    if (e.cost > gold) {
      setErrorMessage("You can't afford this item")
    } else {
      let newGold = gold - e.cost
      playerContext.dispatch({ type: "FIELD", fieldName: "gold", payload: newGold })

      //look to see if there is already one in the backpack
      backpack.map((element) => {
        if (element.name === e.name) {
          count = element.quantity + 1
        }})
        setMessage("You purchased: " + e.name)
        playerContext.dispatch({ type: "ADDITEM", payload: {...e, quantity: count} })
    }

    if (e.subtype === "Quest") {
      //check to see if on quest
      const foundQuests = currentQuests.filter((quest) => quest.objective === e.name)

      //if on quest update quest progress
      foundQuests.forEach((q) => {
        // console.log("Found Matching Quest: " + q.name)
        const newProgress = q.progress + 1
        //If yes, update the quest progress
        playerContext.dispatch({ type: "PICKUPQUEST", payload: {...q, progress: newProgress } })
      })
    }
  }

  function sellItem(e) {
    setMessage("Selling: " + e.name)
    let newQuantity = e.quantity - 1
    let newGold = gold + e.sellValue
    playerContext.dispatch({ type: "FIELD", fieldName: "gold", payload: newGold })
    playerContext.dispatch({ type: "CONSUMEITEM", payload: {...e, quantity:newQuantity} })
  }

  const renderToolTipWeapon = (props) => (
    <Tooltip id="buy-weapon-toolip" {...props}>
      <b>Current Equipped</b><br />
      Name: <i>{mainHand.name}</i><br />
      Damage: ({mainHand.minValue}/{mainHand.maxValue})
    </Tooltip>
  )

  function renderToolTipArmor(props) {
    switch (props) {
      case "head":
        props = head
        break
      case "feet":
        props = feet
        break
      case "hands":
        props = hands
        break
      case "chest":
        props = chest
        break
      case "shoulders":
        props = shoulders
        break
      case "pants":
        props = pants
        break
      case "offHand":
        props = offHand
        break
    }

    return (
      <Tooltip id="buy-armor-toolip" {...props}>
        <b>Current Equipped</b><br />
        Name: <i>{props.name}</i><br />
        Armor: ({props.minValue})
      </Tooltip>
    )
  }

  const shopContents = shopInventory.map((item) => (
    <React.Fragment>
      {item.type === "Consumable" ? (
        <tr>
          <DisplayItem item={item}/>
          <td>{item.cost}</td>
          <td><Button size="sm" onClick={() => buyItem(item)}>Buy</Button></td>
        </tr>
      ) : ("")}
      {item.subtype === "mainHand" ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderToolTipWeapon}
        >
          <tr>
            <DisplayItem item={item}/>
            <td>{item.cost}</td>
            <td><Button size="sm" onClick={() => buyItem(item)}>Buy</Button></td>
          </tr>
        </OverlayTrigger>
      ) : ("")}
      {item.type === "Equipable" && item.subtype !== "mainHand" ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderToolTipArmor(item.subtype)}
        >
          <tr>
            <DisplayItem item={item}/>
            <td>{item.cost}</td>
            <td><Button size="sm" onClick={() => buyItem(item)}>Buy</Button></td>
          </tr>
        </OverlayTrigger>
      ) : ("")}
      {item.type === "Other" ? (
        <tr>
          <DisplayItem item={item}/>
          <td>{item.cost}</td>
          <td><Button size="sm" onClick={() => buyItem(item)}>Buy</Button></td>
        </tr>
      ) : ("")}
    </React.Fragment>
  ))

  const contents = backpack.map((item) => (
    <React.Fragment>
      {item.type === "Consumable" ? (
        <tr>
          <DisplayItem item={item}/>
          <td>{item.sellValue}</td>
          <td>
            <Button size="sm" onClick={() => sellItem(item)}>Sell</Button></td>
        </tr>
      ) : ("")}
      {item.subtype === "mainHand" ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderToolTipWeapon()}
        >
          <tr>
            <DisplayItem item={item}/>
            <td>{item.sellValue}</td>
            <td><Button size="sm" onClick={() => sellItem(item)}>Sell</Button></td>
          </tr>
        </OverlayTrigger>
      ) : ("")}
      {item.type === "Equipable" && item.subtype !== "mainHand" ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderToolTipArmor(item.subtype)}
        >
          <tr>
            <DisplayItem item={item}/>
            <td>{item.sellValue}</td>
            <td><Button size="sm" onClick={() => sellItem(item)}>Sell</Button></td>
          </tr>
        </OverlayTrigger>
      ) : ("")}
      {item.type === "Other" ? "" : ""}
    </React.Fragment>
  ))

  return (
    <Container>
      <Row>
        <b>Bob's Happy Shop</b>
      </Row>
      <Row>
        <Table striped bordered hover size="sm">
          <thead><tr>
              <th>Name</th>
              <th>Type</th>
              <th>#</th>
              <th>Cost</th>
              <th>Buy?</th>
            </tr></thead>
          <tbody>{shopContents}</tbody>
        </Table>
      </Row>
      <Row>
        <b>Backpack</b> (You have {gold} gold pieces)
      </Row>
      <Row>
        <Table striped bordered hover size="sm">
          <thead><tr>
              <th>Name</th>
              <th>Type</th>
              <th>#</th>
              <th>Sell Value</th>
              <th>Sell?</th>
            </tr></thead>
          <tbody>{contents}</tbody>
        </Table>
      </Row>
      <Row><Col>

        {message ? (
          <Alert variant="success" onClose={() => setMessage("")} dismissible>
            {message}
          </Alert>
        ) : ("")}
        {errorMessage ? (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage("")}
            dismissible
          >{errorMessage}</Alert>
        ) : ("")}
      </Col></Row><br />
      <Row>
        <Button onClick={() => playerContext.dispatch({ type: 'GOTO', payload: 'TOWN' })}>Go Back to Town</Button>
      </Row>
    </Container>
  )
}

export default Shop;
