export const monsters = [
    {
        id: 5,
        level: 0,
        name:'Ugly Kobold',
        description: "It's a huge ugly rat standing on it's back legs. The kobold is glowing purple, the same color as the cloud over the explosion. It looks like it's picked up a stick to hit you with!",
        currentHealth:5,
        maxHealth:5,
        power:2,
        goldDropped: 1,
        essenceDropped: 2,
        dropTable: "starterLoot",
        img: "Kobold",
        debuffs: []
    },
    {
        id: 1,
        level: 2,
        name:'Troll',
        description: "This is HIS swamp!",
        currentHealth:20,
        maxHealth:20,
        power:5,
        goldDropped: 5,
        essenceDropped: 2,
        dropTable: "forestLoot2",
        debuffs: []
    },
    {
        id: 2,
        level: 1,
        name:'Kobold',
        description: "Ugly little git",
        currentHealth:5,
        maxHealth:5,
        power:2,
        goldDropped: 1,
        essenceDropped: 1,
        img: "Kobold",
        dropTable: "forestLoot1",
        debuffs: []
    },
    {
        id: 6,
        level: 1,
        name:'Rodent of Unusual Size',
        description: "What, is this a fire swamp?",
        currentHealth:6,
        maxHealth:6,
        power:3,
        goldDropped: 2,
        essenceDropped: 2,
        img: "ROUS",
        dropTable: "forestLoot1",
        debuffs: []
    },
    {
        id: 7,
        level: 1,
        name:'Vampire Bat',
        description: "Like bats needed to be more creepy, this one is glowing purple.",
        currentHealth:8,
        maxHealth:8,
        power:2,
        goldDropped: 1,
        essenceDropped: 2,
        img: "Bat",
        dropTable: "forestLoot1",
        debuffs: []
    },
    {
        id: 3,
        level: 2,
        name:'Donkey',
        description: "Everybody loves a parfait.",
        currentHealth:15,
        maxHealth:15,
        power:4,
        goldDropped: 2,
        essenceDropped: 2,
        dropTable: "forestLoot2",
        debuffs: []
    },
    {
        id: 8,
        level: 2,
        name:'Slime',
        description: "This is no easy blue slime.",
        currentHealth:25,
        maxHealth:25,
        power:4,
        goldDropped: 2,
        essenceDropped: 5,
        dropTable: "forestLoot2",
        debuffs: []
    },
    {
        id: 4,
        level: 3,
        name:'Dragon',
        description: "Too Scary.",
        currentHealth:50,
        maxHealth:50,
        power:20,
        goldDropped: 20,
        essenceDropped: 5,
        dropTable: "forestLoot3",
        debuffs: []
    },
    {
        id: 9,
        level: 1,
        name:'Abom Snake',
        description: "Snakes tempted by the essence.",
        currentHealth:6,
        maxHealth:6,
        power:3,
        goldDropped: 1,
        img: "Snake",
        essenceDropped: 3,
        dropTable: "forestLoot1",
        debuffs: []
    },
]