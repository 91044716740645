import React, {useContext, useState} from 'react'
import { PlayerContext } from '../App'
import {Alert, Card, Table, Container, Button, Row, Tab, Tabs, Col} from 'react-bootstrap'
import DisplayBonus from './DisplayBonus'
import DisplayItem from './DisplayItem'
import DisplayItemButton from './DisplayItemButton'

function CharDetails() {
    const playerContext = useContext(PlayerContext)
    const [message, setMessage] = useState('')
    const { playerName, currentHealth, maxHealth, attackPower, magicPower, gold, armor, minDamage, maxDamage, essence, 
            backpack, head, shoulders, chest, pants, hands, feet, mainHand, offHand, location, email, 
            currentMana, maxMana, forestDepth, critChance, critDamage, dodge, magicCritChance} = playerContext.state
    
    const ironStyle = { backgroundColor: "lightgreen" }
    const steelStyle = { backgroundColor: "lightblue" }
    const mithrilStyle = { backgroundColor: "gold" }

    function consumeItem (e) {
        setMessage("Using: " + e.name)
        if (e.subtype === "Heal") {
            let newHealth
            if (currentHealth + e.minValue > maxHealth) {
                newHealth = maxHealth
            } else {
                newHealth = currentHealth + e.minValue
            }
            let newQuantity = e.quantity - 1
            playerContext.dispatch({ type: 'FIELD', fieldName: 'currentHealth', payload: newHealth})
            playerContext.dispatch({ type: "CONSUMEITEM", payload: {...e, quantity: newQuantity }})
        } else {
            console.log("I don't know how to use " + e.name)
        }
    }
    function addEquipmentToBackPack(oldArmor) {
        let foundItem
        backpack.map (element => {
            if (element.name === oldArmor.name) {
                foundItem = element
            }
        })
        if (foundItem) {
            playerContext.dispatch({ type: "ADDITEM", payload: { ...oldArmor, quantity: foundItem.quantity+1 }})
        } else {
            let newQuantity = 1
            playerContext.dispatch({ type: "ADDITEM", payload: { ...oldArmor, quantity: newQuantity }})
        }
    }

    function unEquipItem(e) {
        setMessage("Unequipping: " + e.name)
        if (e.subtype === "mainHand") {
            playerContext.dispatch({ type: "FIELD", fieldName: "minDamage", payload: 0})
            playerContext.dispatch({ type: "FIELD", fieldName: "maxDamage", payload: 0})   
       } else {
          let newArmor = armor - e.minValue
          playerContext.dispatch({ type: "FIELD", fieldName: "armor", payload: newArmor})
       }

       if (e.bonus) {
           console.log("Need to subtract bonuses and reduce values")
           e.bonus.abilities.forEach(ability => {
            // console.log(ability + " : " + e.bonus.abilityMod)
            switch (ability) {
                case "strength": 
                    const updatedAP = attackPower - (2 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "attackPower", payload: updatedAP})
                    break
                case "intelligence": 
                    const updatedMP = magicPower - (2 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "magicPower", payload: updatedMP})
                    break
                case "constitution": 
                    const updatedHealth = maxHealth - (5 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "maxHealth", payload: updatedHealth})
                    break
                case "spirit": 
                    const updatedMana = maxMana - (2 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "maxMana", payload: updatedMana})
                    break
                case "agility": 
                    const updatedCritDamage = critDamage - (10 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "critDamage", payload: updatedCritDamage})
                    break
                case "critChance": 
                    const updatedCritChance = critChance - (2 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "critChance", payload: updatedCritChance})
                    break
                case "dodge": 
                    const updatedDodge = dodge - (2 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "dodge", payload: updatedDodge})
                    break
                case "magicCritChance": 
                    const updatedMagicCrit = magicCritChance - (2 * e.bonus.abilityMod)
                    playerContext.dispatch({ type: "FIELD", fieldName: "magicCritChance", payload: updatedMagicCrit})
                    break
            }
        })
       }
       playerContext.dispatch({ type: "FIELD", fieldName: e.subtype, payload: { name: "", subtype: e.subtype, minValue:"", maxValue:"", sellValue:""}})
       addEquipmentToBackPack(e)
    }

    function equipItem (e) {
        setMessage("Equipping: " + e.name)
        //set new stats
        let oldArmor
        if (e.subtype === "mainHand") {
            playerContext.dispatch({ type: "FIELD", fieldName: "minDamage", payload: e.minValue})
            playerContext.dispatch({ type: "FIELD", fieldName: "maxDamage", payload: e.maxValue})
            oldArmor = mainHand    
        } else {
            if (e.subtype === "head") {oldArmor = head}
            else if (e.subtype === "shoulders") {oldArmor = shoulders}
            else if (e.subtype === "chest") {oldArmor = chest}
            else if (e.subtype === "hands") {oldArmor = hands}
            else if (e.subtype === "pants") {oldArmor = pants}
            else if (e.subtype === "feet") {oldArmor = feet}
            else if (e.subtype === "offHand") {oldArmor = offHand}
            // console.log("updating armor" + e.subtype)
            let newArmor = armor + e.minValue - oldArmor.minValue
            playerContext.dispatch({ type: "FIELD", fieldName: "armor", payload: newArmor})
        }

        //skip these steps if the armor is the same as before
        if (e.name !== oldArmor.name) {
            //remove item from backpack
            playerContext.dispatch({ type: "CONSUMEITEM", payload: { ...e, quantity: e.quantity-1 }})
    
            //add old to backpack
            if (oldArmor.name) {
                addEquipmentToBackPack(oldArmor)
            }
            
            //calculate bonus
            if (e.bonus) {
                console.log ("need to calculate new stat bonuses")
                e.bonus.abilities.forEach(ability => {
                    console.log(ability + " : " + e.bonus.abilityMod)
                    switch (ability) {
                        case "strength": 
                            const updatedAP = attackPower + (2 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "attackPower", payload: updatedAP})
                            break
                        case "intelligence": 
                            const updatedMP = magicPower + (2 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "magicPower", payload: updatedMP})
                            break
                        case "constitution": 
                            const updatedHealth = maxHealth + (5 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "maxHealth", payload: updatedHealth})
                            break
                        case "spirit": 
                            const updatedMana = maxMana + (2 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "maxMana", payload: updatedMana})
                            break
                        case "agility": 
                            const updatedCritDamage = critDamage + (10 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "critDamage", payload: updatedCritDamage})
                            break
                        case "critChance": 
                            const updatedCritChance = critChance + (2 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "critChance", payload: updatedCritChance})
                            break
                        case "dodge": 
                            const updatedDodge = dodge + (2 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "dodge", payload: updatedDodge})
                            break
                        case "magicCritChance": 
                            const updatedMagicCrit = magicCritChance + (2 * e.bonus.abilityMod)
                            playerContext.dispatch({ type: "FIELD", fieldName: "magicCritChance", payload: updatedMagicCrit})
                            break
                    }
                })
            }

            //equip item
            playerContext.dispatch({ type: "FIELD", fieldName: e.subtype, payload: { ...e }})    
        }
    }

    const contents = backpack.map((item) => 
            <React.Fragment>
                {item.type === "Consumable" ? (<tr>
                    <DisplayItem item={item} />
                    <td><Button size="sm" onClick={() => consumeItem(item)}>Use</Button></td>
                </tr>) :  ("")}
                {item.subtype === "mainHand" ? (<tr>
                    <DisplayItem item={item} />
                    <td><Button size="sm" onClick={() => equipItem(item)}>Equip</Button></td>
                </tr>) :  ("")}
                {item.type === "Equipable" && item.subtype !== "mainHand" ? (<tr>
                    <DisplayItem item={item} />
                    <td><Button size="sm" onClick={() => equipItem(item)}>Equip</Button></td>
                </tr>) :  ("")}
                {item.type === "Other" ? (<tr>
                    <DisplayItem item={item} />
                    <td>-</td>
                </tr>) :  ("")}
            </React.Fragment>
    )

    return (
        <Container>
            <Tabs defaultActiveKey='charDetail' id='charDetails'>
                <Tab eventKey ='charDetail' title="Character Details">
                    <Table striped bordered hover size="sm">
                        <thead><th>Stat Name</th><th>Stat Value</th></thead>
                        <tbody><tr><td>Name</td><td>{playerName}</td></tr>
                        <tr><td>forestDepth</td><td>{forestDepth}</td></tr>
                        <tr><td>Health</td><td>{currentHealth}/{maxHealth}</td></tr>
                        <tr><td>Mana</td><td>{currentMana}/{maxMana}</td></tr>
                        <tr><td>Unspent Essence</td><td>{essence}</td></tr>
                        <tr><td>Armor</td><td>{armor}</td></tr>
                        <tr><td>Attack Power</td><td>{attackPower}</td></tr>
                        <tr><td>Weapon Damage</td><td>{minDamage}-{maxDamage}</td></tr>
                        <tr><td>Critical Strike Chance</td><td>{critChance}</td></tr>
                        <tr><td>Critical Strike Damage</td><td>{critDamage}</td></tr>
                        <tr><td>Dodge</td><td>{dodge}</td></tr>
                        <tr><td>Magic Power</td><td>{magicPower}</td></tr>
                        <tr><td>Magic Critical Strike Chance</td><td>{magicCritChance}</td></tr>
                        <tr><td>Gold:</td><td>{gold}</td></tr></tbody>
                    </Table>
                </Tab>
                <Tab eventKey ='charEquipment' title="Equipment and Backpack">
                    <Table striped bordered hover size="sm">
                        <thead><tr>
                            <th>Equipment</th>
                            <th>Item Equipped</th>
                            <th>Unequip Item</th>
                        </tr></thead>
                        <tbody><tr>
                            <td>Helmet</td>
                            {head.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={head} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(head)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        <tr>
                            <td>Shoulders</td>
                            {shoulders.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={shoulders} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(shoulders)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        <tr>
                            <td>Chest</td>
                            {chest.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={chest} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(chest)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        <tr>
                            <td>Hands</td>
                            {hands.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={hands} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(hands)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        <tr>
                            <td>Pants</td>
                            {pants.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={pants} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(pants)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        <tr>
                            <td>Feet</td>
                            {feet.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={feet} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(feet)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        <tr>
                            <td>Off Hand</td>
                            {offHand.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={offHand} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(offHand)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        <tr>
                            <td>Main Hand</td>
                            {mainHand.name ? (
                                <React.Fragment>
                                    <td><DisplayItemButton item={mainHand} /></td>
                                    <td><Button size="sm" onClick={() => unEquipItem(mainHand)}>Unequip</Button></td>
                                </React.Fragment>
                            ) : (<React.Fragment><td>-</td><td>-</td></React.Fragment>)}
                        </tr>
                        </tbody>
                    </Table>
                    <Table striped bordered hover size="sm">
                        <thead><tr>
                            <th>Backpack</th>
                            <th>Type</th>
                            <th>#</th>
                            <th>Action</th>
                        </tr></thead>
                        <tbody>{contents}</tbody>
                    </Table>
                </Tab>
            </Tabs>
            <Row><Col>
                {message ? (<Alert variant='success' onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
            </Col></Row>
        </Container>
    )
}

export default CharDetails