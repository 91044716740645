import React, {useContext} from 'react'
import { FightContext } from './Fight'
import { Card, Table, ProgressBar } from 'react-bootstrap'

function MonsterCard() {
    const fightContext = useContext(FightContext)
    const { monster, fightStatus } = fightContext.fightState

    const debuffList = monster.debuffs.map((item) => (
        <tr>
          <td>{item.name}</td>
          <td>{item.value}</td>
        </tr>
      ))

    return (
        <Card border="danger">
            <Card.Header>{monster.name}</Card.Header>
            <Card.Body>
                {monster.img ? (<Card.Img src={ require("../img/" + monster.img + ".png")}/>) : ("")}
                <Card.Text>
                    <b>Health:</b> {monster.currentHealth}/{monster.maxHealth}
                    <ProgressBar variant='success' animated now={(monster.currentHealth/monster.maxHealth) * 100} />
                    <br />
                    {monster.description}
                    <Table striped bordered hover size="sm">
                        <thead><tr>
                            <th>Debuff</th>
                            <th>Value</th>
                        </tr></thead>
                        <tbody>{debuffList}</tbody>
                    </Table>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default MonsterCard
