import React, {useContext, useState } from 'react'
import { PlayerContext } from '../App'
import {Container, Row, Card, CardDeck, Button, Table, ListGroup} from 'react-bootstrap'
import DisplayQuest from './DisplayQuest'

const initialQuestState = {
    id: 0,
    name: "",
    description: "",
    status: "",
    type: "",
    objective: "",
    totalAmountNeeded: "",
    reward: [
    ]
}
function QuestLog() {
    const playerContext = useContext(PlayerContext)
    const { playerName, currentQuests , backpack, gold} = playerContext.state
    const [questShown, setQuestShown] = useState(initialQuestState)

    function turnInQuest (e) {
        
        let count = 1

        e.reward.map(item => {
            if (item.type === "Other") {
                if (item.name === "Gold") {
                    const newGold = gold + item.quantity
                    playerContext.dispatch({ type: 'FIELD', fieldName: 'gold', payload: newGold})
                }
            } else {
                //look to see if there is already one in the backpack
                backpack.map (element => {
                    if (element.name === item.name) {
                        count = element.quantity + item.quantity
                    }
                })
                playerContext.dispatch({ type: "ADDITEM", payload: {...item, quantity: count }})
                setQuestShown(initialQuestState)
            }
        })

        //remove quest from quest log
        playerContext.dispatch({ type: "COMPLETEQUEST", payload: { ...e, status:"Complete" }})   

        //if collection type - remove items
        if (e.type === "Collection") {
            //find item in backpack
            let questItem 
            backpack.map (element => {
                if (element.name === e.objective) {
                    questItem = element
                }
            })

            //set item new quantity
            playerContext.dispatch({ type: "CONSUMEITEM", payload: { ...questItem, quantity: 0 }})
        }

        if (e.id === 6) {
            playerContext.dispatch({ type: 'FIELD', fieldName: 'maxForestDepth', payload: 2})
        }
        setQuestShown(initialQuestState)    
    }

    const showCurrentQuests = currentQuests.map((item) => 
        <React.Fragment>
        {item.status === "Complete" ? ("") : (
            <React.Fragment>
                {item.progress >= item.totalAmountNeeded ? (
                    <tr style={{ backgroundColor: "lightgreen"}}>
                        <td>{item.name}</td>
                        <td>{item.type}</td>
                        <td>{item.objective}</td>
                        <td><Button size='sm' onClick={() => setQuestShown(item)}>Details</Button></td>
                    </tr>
                ) : (
                    <tr>
                        <td>{item.name}</td>
                        <td>{item.type}</td>
                        <td>{item.objective}</td>
                        <td><Button size='sm' onClick={() => setQuestShown(item)}>Details</Button></td>
                    </tr>
                )}
            </React.Fragment>
        )}
        </React.Fragment>
    )

    const rewards = questShown.reward.map((item) => 
    <React.Fragment>
      {item.type === "Consumable" ? (<tr>
          <td>{item.name}</td>
          <td>{item.quantity}</td>
          <td>-</td>
      </tr>) :  ("")}
      {item.subtype === "mainHand" ? (<tr>
          <td>{item.name}</td>
          <td>{item.quantity}</td>
          <td>Damage: {item.minValue}-{item.maxValue}</td>
      </tr>) :  ("")}
      {item.type === "Equipable" && item.subtype !== "mainHand" ? (<tr>
          <td>{item.name}</td>
          <td>{item.quantity}</td>
          <td>Armor: {item.minValue}</td>
      </tr>) :  ("")}
      {item.type === "Other" ? (<tr>
          <td>{item.name}</td>
          <td>{item.quantity}</td>
          <td>-</td>
      </tr>) :  ("")}
    </React.Fragment>
    )

    const showQuestDetails = (
        <React.Fragment>
            <DisplayQuest quest={questShown}/>
            <Card.Footer>
                <Button 
                    disabled = {questShown.totalAmountNeeded > questShown.progress}
                    onClick={() => turnInQuest(questShown)}>
                Turn In Quest</Button>
            </Card.Footer>
        </React.Fragment>
    )

    return (
        <Container>
            <Row>
                <h1>{playerName}'s Quest Log</h1>
            </Row>
            <Table striped bordered hover size='sm'>
                <thead><tr>
                    <th>Quest Name</th>
                    <th>Type</th>
                    <th>Objective</th>
                    <th>Show Details</th>
                </tr></thead>
                <tbody>{showCurrentQuests}</tbody>
            </Table>
            <Card border="primary">
                {questShown.id === 0 ? ("Select a Quest to see more details") : (showQuestDetails)}
            </Card>
        </Container>
    )
}

export default QuestLog
