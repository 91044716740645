import React, {useContext, useState} from 'react'
import { PlayerContext } from '../App'
import Button from 'react-bootstrap/Button'
import { CognitoUserPool , AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import Container from 'react-bootstrap/Container'
import { Alert, Row, Form, Table, ProgressBar } from 'react-bootstrap'

function Password() {
    const playerContext = useContext(PlayerContext)
    const { email } = playerContext.state
    const [existingPwd, setExistingPwd] = useState('')
    const [pwd, setPwd] = useState('')
    const [confirmPwd, setConfirmPwd] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [message, setMessage] = useState('')

    const poolData = {
        UserPoolId: 'us-east-1_8YNxc0AO5',
        ClientId: '4d4b9qdvoc5lgpe43ktdv01hep'
    }

    const UserPool = new CognitoUserPool(poolData)

    function handleChangePasswordClick () {
        //Check that new passwords match
        if (pwd === confirmPwd) {
            //Login and get token
            const user = new CognitoUser ({
                Username: email.toLowerCase(),
                Pool: UserPool
            })
    
            const authDetails = new AuthenticationDetails ({
                Username: email.toLowerCase(),
                Password: existingPwd
            })
     
            user.authenticateUser(authDetails, {
                onSuccess: data => {
                    //Existing Password Verified
                    //Change password
                    setMessage("Logged in")
                    user.changePassword(existingPwd, confirmPwd, (pwdErr, result) => {
                        if (pwdErr) {
                            setErrorMessage(JSON.stringify(result))
                        } else {
                            setMessage("Password Changed")
                        }
                    })
                },
    
                onFailure: err=> {
                    setErrorMessage(err['message'])
                },
    
                newPasswordRequired: data => {
                    console.log('newPasswordRequired:', data)
                }
            })
        } 
        else {
            setErrorMessage("Your passwords do not match.")
        }

    }

    return (
      <Container>
        <Row><h3>Change Password</h3></Row>
            <Form>
                <Form.Group controlId="formRegEmail">
                    <Form.Label>Existing Password:</Form.Label>
                    <Form.Control 
                        type="password" 
                        placeholder="Enter Current Password" 
                        value={existingPwd}
                        onChange={(e) =>
                            setExistingPwd(e.currentTarget.value)
                        }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control 
                        type="password" 
                        placeholder="Enter New Password" 
                        value={pwd}
                        onChange={(e) =>
                            setPwd(e.currentTarget.value)
                        }
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Control 
                        type="password" 
                        placeholder="Re-Enter Password" 
                        value={confirmPwd}
                        onChange={(e) =>
                            setConfirmPwd(e.currentTarget.value)
                        }
                    />
                </Form.Group>
                <Button onClick={() => handleChangePasswordClick()} >Change Password</Button>
            </Form>     
        <Row>
            {message ? (<Alert variant='success'  onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
            {errorMessage ? (<Alert variant='danger'  onClose={() => setErrorMessage("")} dismissible>{errorMessage}</Alert>) : ("")}
        </Row>
      </Container>
    )
}

export default Password