import React, { useContext } from 'react'
import { PlayerContext } from '../App'
import { Card, Button } from 'react-bootstrap'

function ArenaDefeat() {
    const playerContext = useContext(PlayerContext)
    const { arenaLevel } = playerContext.state

    function handleEndRunClick() {
        playerContext.dispatch({ type: 'ARENAEND' })
    }
    
    return (
        <Card>
            <Card.Header>Arena Run Over</Card.Header>
            <Card.Body><Card.Text>You made it to Round #{arenaLevel}</Card.Text></Card.Body>
            <Card.Footer>
                <Button 
                    size='sm' 
                    onClick={() => handleEndRunClick() }>
                    End Run
                </Button>
            </Card.Footer>
        </Card>
    )
}

export default ArenaDefeat