import { restartState } from '../Data/RestartState'

async function saveTopArena (username, playerName, topLevel) {
  const arenaBody = {
    username: username,
    playerName: playerName,
    level: topLevel
  }
  console.log("attempting to save top arena")
  const arenaBodyPost = JSON.stringify(arenaBody)
  console.log(arenaBodyPost)
  try {
      fetch('https://k15a1sgec8.execute-api.us-east-1.amazonaws.com/test/arena', {
          method: 'POST',
          mode: 'cors',
          headers: {
              'Content-Type': 'application/json',
          },
          body: arenaBodyPost,
      })
      .then(response => response.json())
      .then(data => {
          console.log("data = " + JSON.stringify(data))
      })
  } catch (error) {
      console.log("Can't load top level")
  }
}

function stateReducer(state,action) {
    let newSpell = ""
    switch(action.type) {
        case 'FIELD':
          return {
            ...state,
            [action.fieldName]: action.payload
          }
        case 'GOTO':
          if (action.payload === "TOWN" || action.payload === "FOREST") {
            return {
              ...state,
              display: action.payload,
              location: action.payload
            }
          } else {
            return {
              ...state,
              display: action.payload
            }
          }
        case 'GOTONEXTSTORY':
          return {
            ...state,
            updateStory: !state.updateStory
          }
        case 'STARTARENA':
          return {
            ...state,
            inArena: true,
            arenaLevel: 1,
            display: "HUNT"
          }
        case 'ARENANEXTMATCH':
          return {
            ...state,
            inArena: true,
            arenaLevel: state.arenaLevel + 1,
            display: "HUNT"
          }
        case 'ARENAEND':
          let bestLevel = state.arenaBestFinish
          if (state.arenaLevel > state.arenaBestFinish) {
            bestLevel = state.arenaLevel
            //api call to set new record
            saveTopArena(state.username, state.playerName, bestLevel)
          }
          return {
            ...state,
            inArena: false,
            arenaLevel: 1,
            inCombat: false,
            arenaBestFinish: bestLevel,
            display: "ARENA"
          }
        case 'UPGRADEBASE':
          return {
            ...state,
            maxForestDepth: state.maxForestDepth + 1,
            display: "BaseUpgradeComplete"
          }
        case 'REST':
          if (action.payload === 'Eat') {
            let newHealth = 0
            let newMana = 0
            if (state.currentHealth + 4 > state.maxHealth) {
              newHealth = state.maxHealth
            } else {
              newHealth = state.currentHealth + 4
            }
            if (state.currentMana + 4 > state.maxMana) {
              newMana = state.maxMana
            } else {
              newMana = state.currentMana + 4
            }
            return {
              ...state,
              gold: state.gold - 1,
              currentHealth: newHealth,
              currentMana: newMana
            }
          }  else if (action.payload === 'Room') {
            return {
              ...state,
              gold: state.gold - 5,
              currentHealth: state.maxHealth,
              currentMana: state.maxMana
            }
          }
        case 'UPGRADETRAIT':
          switch (action.payload.name) {
            case "strength":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                attackPower: state.attackPower + 2,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "intelligence":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                magicPower: state.magicPower + 2,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "spirit":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                currentMana: state.currentMana + 2,
                maxMana: state.maxMana + 2,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "constitution":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                currentHealth: state.currentHealth + 10,
                maxHealth: state.maxHealth + 10,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "agility":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                critDamage: state.critDamage + 10,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "dodge":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                dodge: state.dodge + 2,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "critChance":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                critChance: state.critChance + 2,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "magicCritChance":
              return {
                ...state,
                essence: state.essence - action.traitCost,
                magicCritChance: state.magicCritChance + 2,
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "magicMissle":
              newSpell = {
                name: "Magic Missle",
                type: "Damage",
                punch: 1,
                manaCost: 2,
                debuff: "",
                debuffPunch: null
              }
              return {
                ...state,
                essence: state.essence - action.traitCost,
                spellList: [...state.spellList.filter(spell => spell.name !== action.payload.name), newSpell],
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "heal":
              newSpell = {
                name: "Heal",
                type: "Heal",
                punch: 5,
                manaCost: 5,
                debuff: "",
                debuffPunch: null
              }
              return {
                ...state,
                essence: state.essence - action.traitCost,
                spellList: [...state.spellList.filter(spell => spell.name !== action.payload.name), newSpell],
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            case "fireball":
              newSpell = {
                name: "Fireball",
                type: "Damage",
                punch: 5,
                manaCost: 10,
                debuff: "Scorched",
                debuffPunch: 1
              }
              return {
                ...state,
                essence: state.essence - action.traitCost,
                spellList: [...state.spellList.filter(spell => spell.name !== action.payload.name), newSpell],
                essenceCategories: [...state.essenceCategories.filter(trait => trait.name !== action.payload.name), action.payload]
                }
            default:
              // console.log("I didn't find trait: " + action.payload.name)
              return {
                ...state
              }
          }
        case 'CONSUMEITEM':
          // console.log("AP: " + JSON.stringify(action.payload))
          if (action.payload.quantity > 0) {
            return {
              ...state,
              backpack: [...state.backpack.filter(item => item.name !== action.payload.name), action.payload]
            }  
          } else {
            // console.log("I made it to the correct portion")
            return {
              ...state,
              backpack: [...state.backpack.filter(item => item.name !== action.payload.name)]
            }
          }
        case "ADDITEM":
          return {
            ...state,
            backpack: [...state.backpack.filter(item => item.name !== action.payload.name), action.payload]
          }  
        
        case "PICKUPQUEST": 
          return {
            ...state,
            currentQuests: [...state.currentQuests.filter(quest=> quest.id !== action.payload.id), action.payload]
          }
        case "COMPLETEQUEST":
          return {
            ...state,
            currentQuests: [...state.currentQuests.filter(quest=> quest.id !== action.payload.id), action.payload]
          }
        case 'CREATECHARACTER':
          return {
            ...state,
            isCreated: true,
            maxHealth: 10,
            currentHealth: 10,
            attackPower: 1,
            spellList: [],
          }
        case 'VICTORY':
          return {
            ...state,
            gold: state.gold + action.payload.goldDropped,
            essence: state.essence + action.payload.essenceDropped
          }
        
        case 'LOGIN':
          return {
            ...state,
            username: action.payload.username,
            isLoginScreen: false,
            isLoadScreen: true
          }
    
        case 'GOBACK':
            return {
              ...state,
              isLoginScreen: false,
              isCreateCharacter: false,
              isLoadCharacter: false,
              isLoadScreen: true
          }
        case 'LOADSTATE':
          return {
            ...state,
            isCreated: action.payload.isCreated,
            isBaseStarted: action.payload.isBaseStarted,
            wood: action.payload.wood,
            stone: action.payload.stone,
            isLoginScreen: action.payload.isLoginScreen,
            isLoadScreen: action.payload.isLoadScreen,
            isLoadCharacter: action.payload.isLoadCharacter,
            isCreateCharacter: action.payload.isCreateCharacter,
            playerName: action.payload.playerName,
            username: action.payload.username,
            email: action.payload.email,
            location: action.payload.location,
            currentTown: action.payload.currentTown,
            display: action.payload.display,
            currentHealth: action.payload.currentHealth,
            maxHealth: action.payload.maxHealth,
            currentMana: action.payload.currentMana,
            maxMana: action.payload.maxMana,
            attackPower: action.payload.attackPower,
            minDamage: action.payload.minDamage,
            maxDamage: action.payload.maxDamage,
            critDamage: action.payload.critDamage,
            critChance: action.payload.critChance,
            dodge: action.payload.dodge,
            magicPower: action.payload.magicPower,
            magicCritChance: action.payload.magicCritChance,
            essence: action.payload.essence,
            essenceCategories: action.payload.essenceCategories,
            spellList: action.payload.spellList,
            backpack: action.payload.backpack,
            head: action.payload.head,
            shoulders: action.payload.shoulders,
            chest: action.payload.chest,
            hands: action.payload.hands,
            pants: action.payload.pants,
            feet: action.payload.feet,
            mainHand: action.payload.mainHand,
            offHand: action.payload.offHand,
            armor: action.payload.armor,
            gold: action.payload.gold,
            forestDepth: action.payload.forestDepth,
            maxForestDepth: action.payload.maxForestDepth,
            inCombat: action.payload.inCombat,
            inArena: action.payload.inArena,
            arenaLevel: action.payload.arenaLevel,
            arenaBestFinish: action.payload.arenaBestFinish,
            currentQuests: action.payload.currentQuests
        }
        case 'RESTART':
            console.log("I'm restarting")
            return {
              ...state,
            playerName: '',
            username: '',
            email: '',
            isCreated: false,
            isLoginScreen: true,
            currentHealth: 10,
            maxHealth: 10,
            currentMana: 10,
            maxMana: 10,
            attackPower: 1,
            minDamage: 1,
            maxDamage: 2,
            critDamage: 50,
            critChance: 0,
            dodge: 0,
            magicPower: 1,
            magicCritChance: 0,
            display: 'STORY',
            location: "TOWN",
            currentTown: "Shriven",
            essence: 0,
            armor: 2,
            gold: 10,
            wood: 0,
            stone: 0,
            forestDepth: 0,
            maxForestDepth: 1,
            storyBoardNum: 1,
            convoWith: "Mayor Billy",
            inCombat: true,
            inArena: false,
            arenaLevel: 1,
            arenaBestFinish: 0,
            isBaseStarted: false,
            isLoginScreen: false,
            isLoadScreen: false,
            isLoadCharacter: false,
            isCreateCharacter: true,
            currentQuests: [],
            essenceCategories: [
                { 
                name: "strength",
                level: 0,
                costToUpgrade: 2
                },
                { 
                name: "constitution",
                level: 0,
                costToUpgrade: 2
                },
                {
                name: "agility",
                level: 0,
                costToUpgrade: 2
                },
                {
                name: "dodge",
                level: 0,
                costToUpgrade: 5
                },
                {
                name: "critChance",
                level: 0,
                costToUpgrade: 3
                },
                {
                name: "intelligence",
                level: 0,
                costToUpgrade: 2
                },
                {
                name: "spirit",
                level: 0,
                costToUpgrade: 2
                },
                {
                name: "magicMissle",
                level: 0,
                costToUpgrade: 2
                },
                {
                name: "heal",
                level: 0,
                costToUpgrade: 5
                },
                {
                name: "fireball",
                level: 0,
                costToUpgrade: 20
                },
                {
                name: "magicCritChance",
                level: 0,
                costToUpgrade: 3
                },
                {
                name: "gathering",
                level: 0,
                costToUpgrade: 2
                },
                {
                name: "stealth",
                level: 0,
                costToUpgrade: 2
                },
            ],
            spellList: [
                {
                name: "Weak Fireball",
                type: "Damage",
                punch: 2,
                debuff: "Scorched",
                debuffPunch: 1
                },
                {
                name: "Minor Heal",
                type: "Heal",
                punch: 5,
                debuff: "",
                debuffPunch: null
                },
            ],
            backpack: [
                {
                name: "Apple",
                type: "Consumable",
                subtype: "Heal",
                quantity: 2,
                minValue: 3,
                maxValue: 3,
                cost: 1,
                sellValue: 1,
                },
            ],
            head: {
                name: "",
                subtype: "head",
                minValue:"",
                maxValue:"",
                cost: "",
                sellValue:"",
            },
            shoulders:{
                name: "",
                subtype: "shoulders",
                minValue:"",
                maxValue:"",
                cost: "",
                sellValue:"",
            },
            chest:{
                name: "Cloth Shirt",
                type: "Equipable",
                subtype: "chest",
                quality: "Basic",
                minValue:1,
                maxValue:1,
                cost: 1,
                sellValue:1,
            },
            hands:{
                name: "",
                subtype: "hands",
                minValue:"",
                maxValue:"",
                cost: "",
                sellValue:"",
            },
            pants:{
                name: "Cloth Pants",
                subtype: "pants",
                type: "Equipable",
                quality: "Basic",
                minValue:1,
                maxValue:1,
                cost: 1,
                sellValue:1,
            },
            feet:{
                name: "",
                subtype: "feet",
                minValue:"",
                maxValue:"",
                cost: "",
                sellValue:"",
            },
            mainHand:{
                name: "Stick",
                subtype: "mainHand",
                type: "Equipable",
                quality: "Basic",
                minValue:1,
                maxValue:2,
                cost: 1,
                sellValue:1,
            },
            offHand:{
                name: "",
                subtype: "offHand",
                minValue:"",
                maxValue:"",
                cost: "",
                sellValue:"",
            },
        }

        case 'LOADCHARACTER':
          return {
            ...state,
            isCreated: true,
            isLoginScreen: false,
            isLoadScreen: false,
            isLoadCharacter: false,
            isCreateCharacter: false,
            isBaseStarted: action.payload.gameData.isBaseStarted,
            wood: action.payload.gameData.wood,
            stone: action.payload.gameData.stone,
            playerName: action.payload.playerName,
            username: action.payload.username,
            location: action.payload.gameData.location,
            currentTown: action.payload.gameData.currentTown,
            display: "CHARDETAILS",
            currentHealth: action.payload.gameData.currentHealth,
            maxHealth: action.payload.gameData.maxHealth,
            currentMana: action.payload.gameData.currentMana,
            maxMana: action.payload.gameData.maxMana,
            attackPower: action.payload.gameData.attackPower,
            minDamage: action.payload.gameData.minDamage,
            maxDamage: action.payload.gameData.maxDamage,
            critDamage: action.payload.gameData.critDamage,
            critChance: action.payload.gameData.critChance,
            dodge: action.payload.gameData.dodge,
            magicPower: action.payload.gameData.magicPower,
            magicCritChance: action.payload.gameData.magicCritChance,
            essence: action.payload.gameData.essence,
            essenceCategories: action.payload.gameData.essenceCategories,
            spellList: action.payload.gameData.spellList,
            backpack: action.payload.gameData.backpack,
            head: action.payload.gameData.head,
            shoulders: action.payload.gameData.shoulders,
            chest: action.payload.gameData.chest,
            hands: action.payload.gameData.hands,
            pants: action.payload.gameData.pants,
            feet: action.payload.gameData.feet,
            mainHand: action.payload.gameData.mainHand,
            offHand: action.payload.gameData.offHand,
            armor: action.payload.gameData.armor,
            gold: action.payload.gameData.gold,
            forestDepth: action.payload.gameData.forestDepth,
            maxForestDepth: action.payload.gameData.maxForestDepth,
            inCombat: false,
            inArena: action.payload.gameData.inArena,
            arenaLevel: action.payload.gameData.arenaLevel,
            arenaBestFinish: action.payload.gameData.arenaBestFinish,
            currentQuests: action.payload.gameData.currentQuests
          }
        default:
          return state
      }
    }

export default stateReducer