export const utilityEssenceList = [
  {
    name: "Gathering",
    sysName: "gathering",
    toolTip:
      "Improves amount of materials that you gather when scaveging in the forest",
    maxLevel: 10,
    preReq: { 
      name: "Strength",
      sysName: "strength",
      level: 1 
    },
    disabled: true,
  },
  {
    name: "Stealth",
    sysName: "stealth",
    toolTip:
      "Decreases the change of being interrupted while gathering or traveling.",
    maxLevel: 10,
    preReq: { 
      name: "Agility",
      sysName: "agility",
      level: 1 
    },
    disabled: true,
  },
];
