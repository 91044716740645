import React, {useContext, useState, useEffect} from 'react'
import { PlayerContext } from '../App'
import Convo from './Convo'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import CardDeck from 'react-bootstrap/CardDeck'
import { shrivenTownInfo } from '../Data/Towns/Shriven/TownInfo'
import { havenTownInfo } from '../Data/Towns/Haven/TownInfo'
import { thornbrookTownInfo } from '../Data/Towns/Thornbrook/TownInfo'
import NonPlayer from './NonPlayer'

function Town() {
    const playerContext = useContext(PlayerContext)
    const { currentTown, forestDepth } = playerContext.state
    const [townInfo, setTownInfo] = useState(shrivenTownInfo)

    //update useEffect to get monster on screen load
    useEffect(() => {
        switch (currentTown) {
            case "Shriven": 
                setTownInfo(shrivenTownInfo)
                break
            case "Haven":
                setTownInfo(havenTownInfo)
                break
            case "Thornbrook":
                setTownInfo(thornbrookTownInfo)
                break
        }
    }, []);

    const townCards = townInfo.buildings.map((building) => 
        <Card border="primary">
            <Card.Header>{building.name}</Card.Header>
            <Card.Body>
                <Card.Img src={ require("../Data/Towns/Shriven/" + building.buildingType + ".png") }/>
                <Card.Text>{building.description}</Card.Text>
            </Card.Body>
            <Card.Footer>
                <Button
                    onClick={() => playerContext.dispatch({ type: 'GOTO', payload: building.buildingType })}
                >{building.buttonDescription}</Button>
            </Card.Footer>
        </Card>
    )

    const showTownsfolk = townInfo.townsfolk.map((person) =>
        <Card border="primary" style={{ width: '18rem' }}>
            <NonPlayer nonPlayer={person} />
            <Card.Footer>
                <Button size='sm' variant="link">Chat</Button>
                <Button size='sm' variant="link">Quests</Button>
            </Card.Footer>
        </Card>
    )

    return (
        <Container>
            <Row>
                <h1>{townInfo.banner}</h1>
            </Row>
            {forestDepth < 1 ? (<Convo convoToLoad="convoFirstMayor"/>) : (
                <React.Fragment>
                    <Row>
                        <CardDeck>
                            {townCards}
                        </CardDeck>
                    </Row>
                    <br />
                    {townInfo.townsfolk ? (
                        <CardDeck>
                            {showTownsfolk}
                        </CardDeck>
                    ) : ("")}
                </React.Fragment>
            )}
        </Container>
    )
}

export default Town
