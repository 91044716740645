import React, {useContext, useState} from 'react'
import { PlayerContext } from '../App'
import { Container, Image, Row, Button, Table, Col, Alert, Spinner } from 'react-bootstrap'
import MapPic from '../img/Map.png'

function GameMap() {
  const playerContext = useContext(PlayerContext)
  const { playerName, currentTown } = playerContext.state
  const [message, setMessage] = useState('')
  const [isTraveling, setIsTraveling] = useState(false)
  const [progressAmount, setProgressAmount] = useState(0)

  //const locations = ["Shriven", "Haven", "Thornbrook"]
  const locations = ["Shriven", "Haven", "Thornbrook"]
  const townOptions = locations.filter((town) => town !== currentTown)

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  async function travelTo (townToGoto) {
    setIsTraveling(true)
    playerContext.dispatch({ type: "FIELD", fieldName: "inCombat", payload: true})
    for (let i = 0; i < 10; i++) {
      await sleep(1000)
      setProgressAmount(i*10)
      //Add in chance to be ambushed
    }
    setIsTraveling(false) 
    setMessage("Traveled to " + townToGoto)
    playerContext.dispatch({ type: "FIELD", fieldName: "currentTown", payload: townToGoto})
    playerContext.dispatch({ type: "FIELD", fieldName: "inCombat", payload: false})
  }

  const towns = townOptions.map((town) => 
    <tr>
      <td>{town}</td>
      <td>
        {isTraveling ? (<Button variant="primary" size="sm" disabled>
          <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          />
          Traveling ({progressAmount}%)...
        </Button>) : (
            <Button 
            disabled={isTraveling}
            size="sm"
            onClick={() => travelTo(town)}>Travel</Button>
        )}
      </td>
    </tr>
  )
  
  return (
    <Container>
        <Row>
          <Image src={MapPic} fluid />
        </Row>
        <Row>
          <Table striped bordered hover size="sm">
            <thead><tr>
                <th>Town (Currently in: {currentTown})</th>
                <th>Travel?</th>
            </tr></thead>
            <tbody>
                  {towns}
            </tbody>
          </Table>
        </Row>
        <Row><Col>
          {message ? (<Alert variant='success' onClose={() => setMessage("")} dismissible>{message}</Alert>) : ("")}
        </Col></Row>
    </Container>
  )
}

export default GameMap
