export const thornbrookTownInfo = {
    name: "Thornbrook",
    banner: "Welcome to Thornbrook!",
    description: "Burned down a thousand years ago by a terrible dragon.",
    buildings: [
        {
            name: "Dragon Fire Inn",
            buildingType: "Inn",
            img: '"./Data/Towns/Thornbrook/Inn.png"',
            description: "Haven Inn is better than the stinky Shriven Inn",
            buttonDescription: "Enter the Inn",
        },
        {
            name: "Billy's Happy Shop",
            buildingType: "Shop",
            img: '"./Data/Towns/Thornbrook/Shop.png"',
            description: "Haven Shop sells Happy Meals - well it should at least",
            buttonDescription: "Enter the Shop",
        },
        {
            name: "Thornbrook Quest Board",
            buildingType: "QuestBoard",
            img: '"./Data/Towns/Thornbrook/QuestBoard.png"',
            description: "The quest board provides info on locally available quests!",
            buttonDescription: "Visit Board"
        }
    ]
}