export const havenTownInfo = {
    name: "Haven",
    banner: "Welcome to Haven!",
    description: "It's you know...a Haven of sorts",
    buildings: [
        {
            name: "Haven Inn",
            buildingType: "Inn",
            img: '"./Data/Towns/Haven/Inn.png"',
            description: "Haven Inn is better than the stinky Shriven Inn",
            buttonDescription: "Enter the Inn",
        },
        {
            name: "Tom's Happy Shop",
            buildingType: "Shop",
            img: '"./Data/Towns/Haven/Shop.png"',
            description: "Haven Shop sells Happy Meals - well it should at least",
            buttonDescription: "Enter the Shop",
        },
        {
            name: "Haven Quest Board",
            buildingType: "QuestBoard",
            img: '"./Data/Towns/Haven/QuestBoard.png"',
            description: "The quest board provides info on locally available quests!",
            buttonDescription: "Visit Board"
        }
    ]
}