export const restartState = {
    playerName: '',
    isCreated: false,
    isLoginScreen: true,
    currentHealth: 10,
    maxHealth: 10,
    currentMana: 10,
    maxMana: 10,
    attackPower: 1,
    minDamage: 1,
    maxDamage: 2,
    magicPower: 1,
    display: "STORY",
    location: "TOWN",
    currentTown: "Shriven",
    essence: 0,
    armor: 2,
    gold: 10,
    wood: 0,
    stone: 0,
    forestDepth: 0,
    maxForestDepth: 1,
    storyBoardNum: 1,
    convoWith: "Mayor Billy",
    inCombat: true,
    isBaseStarted: false,
    isLoginScreen: false,
    isLoadScreen: false,
    isLoadCharacter: false,
    isCreateCharacter: true,
    currentQuests: [],
    essenceCategories: [
      { 
        name: "strength",
        level: 0,
        costToUpgrade: 2
      },
      { 
        name: "constitution",
        level: 0,
        costToUpgrade: 2
      },
      {
        name: "agility",
        level: 0,
        costToUpgrade: 2
      },
      {
        name: "dodge",
        level: 0,
        costToUpgrade: 5
      },
      {
        name: "critChance",
        level: 0,
        costToUpgrade: 3
      },
      {
        name: "intelligence",
        level: 0,
        costToUpgrade: 2
      },
      {
        name: "spirit",
        level: 0,
        costToUpgrade: 2
      },
      {
        name: "magicMissle",
        level: 0,
        costToUpgrade: 2
      },
      {
        name: "heal",
        level: 0,
        costToUpgrade: 5
      },
      {
        name: "fireball",
        level: 0,
        costToUpgrade: 20
      },
      {
        name: "magicCritChance",
        level: 0,
        costToUpgrade: 3
      },
      {
        name: "gathering",
        level: 0,
        costToUpgrade: 2
      },
      {
        name: "stealth",
        level: 0,
        costToUpgrade: 2
      },
    ],
    spellList: [
      {
        name: "Weak Fireball",
        type: "Damage",
        punch: 2,
        debuff: "Scorched",
        debuffPunch: 1
      },
      {
        name: "Minor Heal",
        type: "Heal",
        punch: 5,
        debuff: "",
        debuffPunch: null
      },
    ],
    backpack: [
      {
        name: "Apple",
        type: "Consumable",
        subtype: "Heal",
        quantity: 2,
        minValue: 3,
        maxValue: 3,
        cost: 1,
        sellValue: 1,
      },
    ],
    head: {
      name: "",
      subtype: "head",
      minValue:"",
      maxValue:"",
      cost: "",
      sellValue:"",
    },
    shoulders:{
      name: "",
      subtype: "shoulders",
      minValue:"",
      maxValue:"",
      cost: "",
      sellValue:"",
    },
    chest:{
      name: "Cloth Shirt",
      subtype: "chest",
      quality: "Basic",
      minValue:1,
      maxValue:1,
      cost: 1,
      sellValue:"1",
    },
    hands:{
      name: "",
      subtype: "hands",
      minValue:"",
      maxValue:"",
      cost: "",
      sellValue:"",
    },
    pants:{
      name: "Cloth Pants",
      subtype: "pants",
      quality: "Basic",
      minValue:1,
      maxValue:1,
      cost: 1,
      sellValue:1,
    },
    feet:{
      name: "",
      subtype: "feet",
      minValue:"",
      maxValue:"",
      cost: "",
      sellValue:"",
    },
    mainHand:{
      name: "Stick",
      subtype: "mainHand",
      quality: "Basic",
      minValue:1,
      maxValue:2,
      cost: 1,
      sellValue:1,
    },
    offHand:{
      name: "",
      subtype: "offHand",
      minValue:"",
      maxValue:"",
      cost: "",
      sellValue:"",
    },
}